import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "antd";

import { withBasket, BasketContext } from "../../Utils/BasketProvider";
import ICatalogSearch from "./model/ICatalogSearch";
import CatalogVehicleNode from "./component/CatalogVehicleNode";
import CatalogVehicleTypeFamilySearch from "./component/CatalogVehicleTypeFamilySearch";
import CatalogFamilySearch from "./component/CatalogFamilySearch";
import ArticleDisplay from "../home/ArticleDisplay";
import HeadbandSearch from "../home/HeadbandSearch";
import CatalogModelYear from "./component/CatalogModelYear";
import CatalogVehicleTypeFamily from "./component/CatalogVehicleTypeFamily";
import CatalogBreadcrumb from "./component/CatalogBreadcrumb";
import CatalogVehicleInfo from "./component/CatalogVehicleInfo";
import CatalogVehicleType from "./component/CatalogVehicleType";

class CatalogSearch extends React.Component<
  {
    history: any;
    catalogSearch: ICatalogSearch;
    setCatalogSearch: any;
    clearCatalogSearch: any;
    setTypeSearch: any;
  },
  {}
> {
  componentDidMount() {
    if (this.props.catalogSearch.level1Id === undefined) {
      this.props.history.push("/home");
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => (
          <div>
            <HeadbandSearch
              showEtai={false}
              showTecdoc={false}
              onSearchArticle={undefined}
              onAdvancedQuery={undefined}
              onSearchEtai={undefined}
              cancelSearch={this.onCancelSearch}
              title={"Recherche de pièces par modèle ou catégorie"}
            />
            <CatalogBreadcrumb />

            <Row gutter={12}>
              <Col span={5} flex={"300px"}>
                {(contextValues.catalogSearch.queryType === "vehicleNode" ||
                  contextValues.catalogSearch.queryType ===
                    "characteristic") && <CatalogVehicleTypeFamilySearch />}

                {(contextValues.catalogSearch.queryType === "vehicleNode" ||
                  contextValues.catalogSearch.queryType ===
                    "characteristic") && <CatalogVehicleInfo />}

                {contextValues.catalogSearch.queryType === "family" && (
                  <CatalogFamilySearch />
                )}
              </Col>

              <Col span={19} style={{ height: "80%" }}>
                {(contextValues.catalogSearch.queryType === "vehicleNode" ||
                  contextValues.catalogSearch.queryType === "characteristic") &&
                  contextValues.catalogSearch.level1Id !== undefined &&
                  contextValues.catalogSearch.level2Id === undefined &&
                  contextValues.catalogSearch.level3Id === undefined &&
                  contextValues.catalogSearch.level4Id === undefined && (
                    <CatalogVehicleNode />
                  )}

                {(contextValues.catalogSearch.queryType === "vehicleNode" ||
                  contextValues.catalogSearch.queryType === "characteristic") &&
                  contextValues.catalogSearch.level1Id !== undefined &&
                  contextValues.catalogSearch.level2Id !== undefined &&
                  contextValues.catalogSearch.level3Id === undefined &&
                  contextValues.catalogSearch.level4Id === undefined && (
                    <CatalogVehicleType />
                  )}

                {contextValues.catalogSearch.queryType === "vehicleNode" &&
                  contextValues.catalogSearch.level1Id !== undefined &&
                  contextValues.catalogSearch.level2Id !== undefined &&
                  contextValues.catalogSearch.level3Id !== undefined &&
                  contextValues.catalogSearch.level4Id === undefined &&
                  contextValues.catalogSearch.modelYear === undefined && (
                    <CatalogModelYear />
                  )}

                {contextValues.catalogSearch.queryType === "vehicleNode" &&
                  contextValues.catalogSearch.level1Id !== undefined &&
                  contextValues.catalogSearch.level2Id !== undefined &&
                  contextValues.catalogSearch.level3Id !== undefined &&
                  contextValues.catalogSearch.level4Id === undefined &&
                  contextValues.catalogSearch.modelYear !== undefined && (
                    <CatalogVehicleTypeFamily />
                  )}

                {((contextValues.catalogSearch.queryType === "vehicleNode" &&
                  contextValues.catalogSearch.level5Id !== undefined) ||
                  (contextValues.catalogSearch.queryType === "family" &&
                    contextValues.catalogSearch.level2Id !== undefined)) && (
                  <ArticleDisplay />
                )}

                {contextValues.catalogSearch.queryType === "characteristic" &&
                  contextValues.catalogSearch.level3Id !== undefined && (
                    <ArticleDisplay />
                  )}
              </Col>
            </Row>
          </div>
        )}
      </BasketContext.Consumer>
    );
  }

  private onCancelSearch = () => {
    //this.props.clearCatalogSearch();
    this.props.setTypeSearch(0);
    this.props.history.push("/home");
  };
}

export default withRouter(withBasket(CatalogSearch));
