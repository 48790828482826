export const findCustomerPlatform = async (): Promise<[]> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  return fetch("/api/customers/" + customerId + "/customerPlatforms", {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      if (
        data !== undefined &&
        data.content !== undefined &&
        data.content.length > 0
      ) {
        return data.content;
      }
      return undefined;
    })
    .catch((err: any) => {
      console.log(err);
    });
};
export const findValuePlatform = async (
  platformId: number,
  name: string
): Promise<string> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/parameters?platform=" +
      platformId +
      "&customer=" +
      customerId +
      "&name=" +
      name,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      if (
        data !== undefined &&
        data.content !== undefined &&
        data.content.length > 0
      ) {
        return data.content[0].value;
      }
      return undefined;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findValueCustomer = async (name: string): Promise<string> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/parameters?customer=" + customerId + "&name=" + name,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      if (
        data !== undefined &&
        data.content !== undefined &&
        data.content.length > 0
      ) {
        return data.content[0].value;
      }
      return undefined;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findParameterValue = (name: string): Promise<string> => {
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    "/api/market/parameters?customer=" + customerId + "&name=" + name,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      if (
        data !== undefined &&
        data.content !== undefined &&
        data.content.length > 0
      ) {
        return data.content[0].value;
      }
      return undefined;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findValueByCustomerId = async (
  name: string,
  accessToken: string,
  customerId: number
): Promise<string> => {
  return fetch(
    "/api/market/parameters?customer=" + customerId + "&name=" + name,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      return data.content[0].value;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findParametersByCustomerIdFunctional = async (
  accessToken: string,
  customerId: number | string
): Promise<any> => {
  return fetch(
    "/api/market/parameters?customer=" + customerId + "&size=1000&page=0",
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      return data.content;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findInitParameters = async (): Promise<any> => {
  return fetch("/api/market/parameters?size=1000&page=0", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      return data.content;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const findInitParametersByPlatform = async (
  accessToken: string,
  platformId: number,
  parameterName: string
): Promise<string> => {
  const customerId = sessionStorage.getItem("customerId");
  return fetch(
    `/api/market/parameters?name=${parameterName}&platform=${platformId}&customer=${customerId}&size=1000&page=0`,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  )
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      return data.content[0].value;
    })
    .catch((err: any) => {
      console.log(err);
    });

  // .then((response: any) => {
  //   return response.json();
  // })
  // .then((data: any) => {
  //   return data.content;
  // })
  // .catch((err: any) => {
  //   console.log(err);
  // });
};

export const getPublicHtmlPage = async (page: string): Promise<string> => {
  if (page !== "INC") {
    return fetch("/api/public/" + page)
      .then((response: any) => {
        if (response.status === 200) {
          return response.text();
        } else {
          return "";
        }
      })
      .catch((err: any) => {
        return "";
      });
  }
  return "";
};
// class ParameterService {
//   private accessToken = sessionStorage.getItem("accessToken");
//   private customerId = sessionStorage.getItem("customerId");

// public async findCustomerPlatform(): Promise<[]> {
//   return fetch("/api/customers/" + this.customerId + "/customerPlatforms", {
//     headers: {
//       Authorization: "Bearer " + this.accessToken,
//       Accept: "application/json",
//       "Content-Type": "application/json;charset=UTF-8",
//     },
//   })
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       if (
//         data !== undefined &&
//         data.content !== undefined &&
//         data.content.length > 0
//       ) {
//         return data.content;
//       }
//       return undefined;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });
// }

// public async findValuePlatform(
//   platformId: number,
//   name: string
// ): Promise<string> {
//   return fetch(
//     "/api/market/parameters?platform=" +
//       platformId +
//       "&customer=" +
//       this.customerId +
//       "&name=" +
//       name,
//     {
//       headers: {
//         Authorization: "Bearer " + this.accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     }
//   )
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       if (
//         data !== undefined &&
//         data.content !== undefined &&
//         data.content.length > 0
//       ) {
//         return data.content[0].value;
//       }
//       return undefined;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });
// }

// public async findValueCustomer(name: string): Promise<string> {
//   return fetch(
//     "/api/market/parameters?customer=" + this.customerId + "&name=" + name,
//     {
//       headers: {
//         Authorization: "Bearer " + this.accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     }
//   )
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       if (
//         data !== undefined &&
//         data.content !== undefined &&
//         data.content.length > 0
//       ) {
//         return data.content[0].value;
//       }
//       return undefined;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });
// }

// public async findValue(name: string): Promise<string> {
//   return fetch(
//     "/api/market/parameters?customer=" + this.customerId + "&name=" + name,
//     {
//       headers: {
//         Authorization: "Bearer " + this.accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     }
//   )
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       if (
//         data !== undefined &&
//         data.content !== undefined &&
//         data.content.length > 0
//       ) {
//         return data.content[0].value;
//       }
//       return undefined;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });
// }

// public async findValueByCustomerId(
//   name: string,
//   accessToken: string,
//   customerId: number
// ): Promise<string> {
//   return fetch(
//     "/api/market/parameters?customer=" + customerId + "&name=" + name,
//     {
//       headers: {
//         Authorization: "Bearer " + accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     }
//   )
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       return data.content[0].value;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });
// }

// public async findParameters(
//   accessToken: string,
//   customerId: number
// ): Promise<string> {
//   return fetch(
//     "/api/market/parameters?customer=" + customerId + "&size=40&page=0",
//     {
//       headers: {
//         Authorization: "Bearer " + accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     }
//   )
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       return data.content;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });
// }

// public async findInitParameters(): Promise<any> {
//   return fetch("/api/market/parameters?size=60&page=0", {
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json;charset=UTF-8",
//     },
//   })
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       return data.content;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });
// }

// public async findInitParametersByPlatform(
//   accessToken: string,
//   platformId: number,
//   parameterName: string
// ): Promise<string> {
//   return fetch(
//     `/api/market/parameters?name=${parameterName}&platform=${platformId}&customer=${this.customerId}&size=60&page=0`,
//     {
//       headers: {
//         Authorization: "Bearer " + accessToken,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     }
//   )
//     .then((response: any) => {
//       return response.json();
//     })
//     .then((data: any) => {
//       return data.content[0].value;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });

//   // .then((response: any) => {
//   //   return response.json();
//   // })
//   // .then((data: any) => {
//   //   return data.content;
//   // })
//   // .catch((err: any) => {
//   //   console.log(err);
//   // });
// }

//   public async getPublicHtmlPage(page: string): Promise<string> {
//     if (page !== "INC") {
//       return fetch("/api/public/" + page)
//         .then((response: any) => {
//           if (response.status === 200) {
//             return response.text();
//           } else {
//             return "";
//           }
//         })
//         .catch((err: any) => {
//           return "";
//         });
//     }
//     return "";
//   }
// }

// export default ParameterService;
