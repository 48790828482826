import React from "react";
import {
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Tag,
  notification,
  Image,
} from "antd";
import IArticle from "../IArticle";
import ArticleWishlistForm from "../ArticleWishlistForm";
import Comment from "./MoreAvailabilities";
import ArticleService from "../ArticleService";
import IArticlePlatforms from "../IArticlePlatforms";
import ArticleUtils from "../ArticleUtils";
import ArticleAvailability from "../ArticleAvailability";
import ArticlePrice from "./ArticlePrice";
import IArticleUsage from "../IArticleUsage";
import { ShoppingCartOutlined, StarOutlined } from "@ant-design/icons";
import BookmarkService from "../../bookmark/BookmarkService";
import { MarketParameterContext } from "../../../Utils/MarketParameterContext";

class SimpleArticleCard extends React.Component<
  {
    article: IArticle;
    onChangeSelect: any;
    onChangeQuantity: any;
    cardStyle: React.CSSProperties;
  },
  {
    article: IArticle | undefined;
    articleUsage: IArticleUsage | undefined;
  }
> {
  private articleService: ArticleService = new ArticleService();
  private articleUtils: ArticleUtils = new ArticleUtils();
  private bookmarkService: BookmarkService = new BookmarkService();
  private customerId = sessionStorage.getItem("customerId");

  constructor(props: {
    article: IArticle;
    onChangeSelect: any;
    onChangeQuantity: any;
    cardStyle: React.CSSProperties;
  }) {
    super(props);

    this.state = {
      article: undefined,
      articleUsage: undefined,
    };
  }

  private title = (article: IArticle): React.ReactElement => (
    <Row>
      <Col flex="40px">
        {this.state.articleUsage && this.state.articleUsage.bookmarked && (
          <Tooltip title="Cet article est dans vos favoris" placement="right">
            <StarOutlined
              style={{ fontSize: 16, fontWeight: 500, color: "#1890ff" }}
            />
          </Tooltip>
        )}
        {this.state.articleUsage && !this.state.articleUsage.bookmarked && (
          <Tooltip title="Ajouter cet article au favoris" placement="right">
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => this.onAddBookmarkByArticle(this, article)}
              type="link"
            >
              <StarOutlined
                style={{ fontSize: 16, fontWeight: 500, color: "#BFBFBF" }}
              />
            </Button>
          </Tooltip>
        )}
      </Col>
      <Col flex="auto">
        {article.reference} :: {article.name}
      </Col>
      <Col flex="70px">
        {this.state.articleUsage &&
          this.state.articleUsage.quantityInWishlists > 0 && (
            <Tooltip
              title={`${this.state.articleUsage.quantityInWishlists} dans le panier`}
              placement="left"
            >
              <Tag color="orange">
                {this.state.articleUsage.quantityInWishlists}
                <ShoppingCartOutlined />
              </Tag>
            </Tooltip>
          )}
      </Col>
      <Col flex="100px" style={{ textAlign: "right" }}>
        <img
          src={
            "/api/market/manufacturers/" +
            article.manufacturer.id +
            "/logo?customer=" +
            this.customerId
          }
          key={article.reference}
          alt={article.reference}
          style={{ maxWidth: 100, maxHeight: 30 }}
        />
      </Col>
    </Row>
  );

  componentDidMount() {
    if (this.props.article) {
      this.loadArticle(this.props.article);
    }
  }

  componentDidUpdate(newProps: {
    article: IArticle;
    onChangeSelect: any;
    onChangeQuantity: any;
    cardStyle: React.CSSProperties;
  }) {
    if (newProps.article && newProps.article.id !== this.props.article.id) {
      this.loadArticle(this.props.article);
    }
  }

  render() {
    return (
      <MarketParameterContext.Consumer>
        {(parameters) => (
          <>
            {this.state.article && (
              <Card
                title={this.title(this.state.article)}
                headStyle={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  backgroundColor: "#FAFAFA",
                }}
                bodyStyle={{ marginBottom: 0 }}
                style={this.props.cardStyle}
              >
                <Row>
                  <Col flex="130px">
                    <Image
                      src={
                        "/api/market/articles/" +
                        this.state.article.id +
                        "/image"
                      }
                      key={this.state.article.reference}
                      alt={this.state.article.reference}
                      style={{ width: "100%", maxWidth: 130, maxHeight: 130 }}
                    />
                  </Col>
                  <Col flex="600px">
                    <ArticleAvailability
                      item={this.state.article}
                      loading={this.state.article.loading}
                      showAvailability={false}
                      showManufacturer={parameters.SHOW_MANUFACTURER}
                      currentSupplierAvailabilityLabel={
                        parameters.SUPPLIER_AVAILABILITY_LABEL
                      }
                      currentSupplierAvailabilityStatus={
                        parameters.SUPPLIER_AVAILABILITY_STATUS
                      }
                      currentSupplierAvailabilityWarningEnabled={
                        parameters.SUPPLIER_AVAILABILITY_WARNING_ENABLED
                      }
                    />
                  </Col>
                  <Col flex="auto">
                    <ArticleWishlistForm
                      item={this.state.article}
                      onSelectPlatform={(value: any) =>
                        this.props.onChangeSelect(value, this.state.article)
                      }
                      onChangeQuantity={this.props.onChangeQuantity}
                    />
                    <br />
                    <Comment
                      item={this.state.article}
                      loading={this.state.article.loading}
                    />
                  </Col>
                  <Col flex="130px">
                    <ArticlePrice
                      item={this.state.article}
                      loading={this.state.article.loading}
                    />
                  </Col>
                </Row>
              </Card>
            )}
          </>
        )}
      </MarketParameterContext.Consumer>
    );
  }

  private onAddBookmarkByArticle = (
    parent: React.Component,
    article: IArticle
  ) => {
    if (this.customerId !== null) {
      this.bookmarkService
        .addArticleBookmark(article, Number(this.customerId))
        .then((response: any) => {
          if (response.status !== 201) {
            notification.error({
              message: "Favoris",
              description: "Impossible d'ajout cet article à vos favoris.",
            });
          } else {
            notification.info({
              message: "Favoris",
              description: "L'article a bien été ajouté à vos favoris.",
              duration: 2,
            });
            this.loadArticle(article);
          }
        });
    }
  };

  private loadArticle = (article: IArticle) => {
    this.articleService
      .findArticleById(article.id)
      .then((articleFind: IArticle) => {
        let articlePlatform: IArticlePlatforms =
          articleFind.articlePlatforms[0];

        if (articlePlatform !== undefined) {
          articleFind.loading = true;
          articleFind.quantity = articlePlatform.salePackaging;

          this.articleUtils.checkArticleAvailability(
            this,
            undefined,
            articleFind,
            false
          );
        }

        this.articleService
          .findArticleUsage(article.id)
          .then((articleUsage: IArticleUsage) => {
            this.setState({
              articleUsage: articleUsage,
              article: articleFind,
            });
          });
      });
  };
}

export default SimpleArticleCard;
