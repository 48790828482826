import { Breadcrumb, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { BasketContext, withBasket } from "../../../Utils/BasketProvider";
import { useCallback, useContext } from "react";
import ICatalogSearch from "../model/ICatalogSearch";
import { withRouter } from "react-router";

interface CatalogBreadcrumbProps {
  history: [string];
}

// Fils d'arriane permettant de tracer
// et de remonter dans l'arborescence de la documentation
const CatalogBreadcrumb = ({
  history,
}: CatalogBreadcrumbProps): React.ReactElement => {
  const basketContext = useContext(BasketContext);

  const onCancelSearch = useCallback(() => {
    basketContext.clearCatalogSearch();
    basketContext.setTypeSearch(0);
    basketContext.setSelectedArticle(undefined);
    history.push("/home");
  }, [basketContext, history]);

  const onCancelLevel2 = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level2Id: undefined,
      level2Name: undefined,
      level3Id: undefined,
      level3Name: undefined,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined,
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  const onCancelLevel3 = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level3Id: undefined,
      level3Name: undefined,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined,
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  const onCancelYear = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined,
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  const onCancelLevel4 = useCallback(() => {
    let catalogSearch: ICatalogSearch = {
      ...basketContext.catalogSearch,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      /*modelYear: undefined*/
    };
    basketContext.setCatalogSearch(catalogSearch);
    basketContext.setSelectedArticle(undefined);
  }, [basketContext]);

  // const onCancelLevel5 = useCallback(() => {
  //   let catalogSearch: ICatalogSearch = {
  //     ...basketContext.catalogSearch,
  //     level4Id: undefined,
  //     level4Name: undefined,
  //     level5Id: undefined,
  //     level5Name: undefined,
  //     /*modelYear: undefined*/
  //   };
  //   basketContext.setCatalogSearch(catalogSearch);
  //   basketContext.setSelectedArticle(undefined);
  // }, [basketContext]);

  return (
    <BasketContext.Consumer>
      {(contextValues) =>
        contextValues.catalogSearch.level1Id !== undefined && (
          <Breadcrumb
            style={{
              marginBottom: 15,
              paddingLeft: 40,
              position: "sticky",
              top: 42,
              backgroundColor: "#FFFFFF",
              // backgroundColor: "red",
              zIndex: 2,
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: 5 }} />
              <Button
                onClick={onCancelSearch}
                type={"link"}
                style={{ margin: 0, padding: 0 }}
              >
                Nouvelle recherche
              </Button>
            </Breadcrumb.Item>

            {contextValues.catalogSearch.level1Name !== undefined &&
              (contextValues.catalogSearch.queryType === "vehicleNode" ||
                contextValues.catalogSearch.queryType === "characteristic") && (
                <Breadcrumb.Item>
                  <Button
                    onClick={onCancelLevel2}
                    type={"link"}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {contextValues.catalogSearch.level1Name}
                  </Button>
                </Breadcrumb.Item>
              )}

            {contextValues.catalogSearch.level1Name !== undefined &&
              contextValues.catalogSearch.queryType === "family" && (
                <Breadcrumb.Item>
                  <Button
                    onClick={onCancelSearch}
                    type={"link"}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {contextValues.catalogSearch.level1Name}
                  </Button>
                </Breadcrumb.Item>
              )}

            {contextValues.catalogSearch.level2Name !== undefined &&
              (contextValues.catalogSearch.queryType === "vehicleNode" ||
                contextValues.catalogSearch.queryType === "characteristic") && (
                <Breadcrumb.Item>
                  <Button
                    onClick={onCancelLevel3}
                    type={"link"}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {contextValues.catalogSearch.level2Name}
                  </Button>
                </Breadcrumb.Item>
              )}

            {contextValues.catalogSearch.level2Name !== undefined &&
              contextValues.catalogSearch.queryType === "family" && (
                <Breadcrumb.Item>
                  <span>{contextValues.catalogSearch.level2Name}</span>
                </Breadcrumb.Item>
              )}

            {contextValues.catalogSearch.level3Name !== undefined && (
              <Breadcrumb.Item>
                <Button
                  onClick={onCancelYear}
                  type={"link"}
                  style={{ margin: 0, padding: 0 }}
                >
                  {contextValues.catalogSearch.level3Name}
                </Button>
              </Breadcrumb.Item>
            )}

            {contextValues.catalogSearch.modelYear !== undefined && (
              <Breadcrumb.Item>
                <Button
                  onClick={onCancelLevel4}
                  type={"link"}
                  style={{ margin: 0, padding: 0 }}
                >
                  {contextValues.catalogSearch.modelYear}
                </Button>
              </Breadcrumb.Item>
            )}

            {contextValues.catalogSearch.level4Name !== undefined && (
              <Breadcrumb.Item>
                <Button
                  // onClick={onCancelLevel4}
                  // type={"link"}
                  type={"text"}
                  style={{ margin: 0, padding: 0 }}
                >
                  {/* <div style={{ margin: 0, padding: 0 }}> */}
                  {contextValues.catalogSearch.level4Name}
                  {/* </div> */}
                </Button>
              </Breadcrumb.Item>
            )}

            {contextValues.catalogSearch.level5Name !== undefined && (
              <Breadcrumb.Item>
                <Button
                  // onClick={onCancelLevel5}
                  // type={"link"}
                  type={"text"}
                  style={{ marginTop: 0, padding: 0 }}
                >
                  {/* <div style={{ margin: 0, padding: 0 }}> */}
                  {contextValues.catalogSearch.level5Name}
                  {/* </div> */}
                </Button>
              </Breadcrumb.Item>
            )}

            {/* {contextValues.catalogSearch.plateNumber !== undefined && (
                <Breadcrumb.Item>
                  <div style={{ margin: "5px", padding: 0 }}>
                    {contextValues.catalogSearch.plateNumber}
                  </div>
                </Breadcrumb.Item>
              )} */}
          </Breadcrumb>
        )
      }
    </BasketContext.Consumer>
  );
};

export default withRouter(withBasket(CatalogBreadcrumb));

// import React from "react";
// import { withBasket, BasketContext } from "../../../Utils/BasketProvider";
// import { Breadcrumb, Button } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";

// import ICatalogSearch from "../model/ICatalogSearch";
// import { withRouter } from "react-router";

// class CatalogBreadcrumb extends React.Component<
//   {
//     history: [string];
//     setTypeSearch: any;
//     catalogSearch: ICatalogSearch;
//     setCatalogSearch: any;
//     clearCatalogSearch: any;
//     setSelectedArticle: any;
//   },
//   {}
// > {
//   render() {
//     return (
//       <BasketContext.Consumer>
//         {(contextValues) =>
//           contextValues.catalogSearch.level1Id !== undefined && (
//             <Breadcrumb
//               style={{
//                 marginBottom: 15,
//                 paddingLeft: 40,
//                 position: "sticky",
//                 top: 42,
//                 backgroundColor: "#FFFFFF",
//                 // backgroundColor: "red",
//                 zIndex: 2,
//               }}
//             >
//               <Breadcrumb.Item>
//                 <FontAwesomeIcon icon={faSearch} style={{ marginRight: 5 }} />
//                 <Button
//                   onClick={this.onCancelSearch}
//                   type={"link"}
//                   style={{ margin: 0, padding: 0 }}
//                 >
//                   Nouvelle recherche
//                 </Button>
//               </Breadcrumb.Item>

//               {contextValues.catalogSearch.level1Name !== undefined &&
//                 (contextValues.catalogSearch.queryType === "vehicleNode" ||
//                   contextValues.catalogSearch.queryType ===
//                     "characteristic") && (
//                   <Breadcrumb.Item>
//                     <Button
//                       onClick={this.onCancelLevel2}
//                       type={"link"}
//                       style={{ margin: 0, padding: 0 }}
//                     >
//                       {contextValues.catalogSearch.level1Name}
//                     </Button>
//                   </Breadcrumb.Item>
//                 )}

//               {contextValues.catalogSearch.level1Name !== undefined &&
//                 contextValues.catalogSearch.queryType === "family" && (
//                   <Breadcrumb.Item>
//                     <Button
//                       onClick={this.onCancelSearch}
//                       type={"link"}
//                       style={{ margin: 0, padding: 0 }}
//                     >
//                       {contextValues.catalogSearch.level1Name}
//                     </Button>
//                   </Breadcrumb.Item>
//                 )}

//               {contextValues.catalogSearch.level2Name !== undefined &&
//                 (contextValues.catalogSearch.queryType === "vehicleNode" ||
//                   contextValues.catalogSearch.queryType ===
//                     "characteristic") && (
//                   <Breadcrumb.Item>
//                     <Button
//                       onClick={this.onCancelLevel3}
//                       type={"link"}
//                       style={{ margin: 0, padding: 0 }}
//                     >
//                       {contextValues.catalogSearch.level2Name}
//                     </Button>
//                   </Breadcrumb.Item>
//                 )}

//               {contextValues.catalogSearch.level2Name !== undefined &&
//                 contextValues.catalogSearch.queryType === "family" && (
//                   <Breadcrumb.Item>
//                     <span>{contextValues.catalogSearch.level2Name}</span>
//                   </Breadcrumb.Item>
//                 )}

//               {contextValues.catalogSearch.level3Name !== undefined && (
//                 <Breadcrumb.Item>
//                   <Button
//                     onClick={this.onCancelLevel3}
//                     type={"link"}
//                     style={{ margin: 0, padding: 0 }}
//                   >
//                     {contextValues.catalogSearch.level3Name}
//                   </Button>
//                 </Breadcrumb.Item>
//               )}

//               {contextValues.catalogSearch.level4Name !== undefined && (
//                 <Breadcrumb.Item>
//                   <Button
//                     onClick={this.onCancelLevel4}
//                     type={"link"}
//                     style={{ margin: 0, padding: 0 }}
//                   >
//                     {contextValues.catalogSearch.level4Name}
//                   </Button>
//                 </Breadcrumb.Item>
//               )}

//               {contextValues.catalogSearch.level5Name !== undefined && (
//                 <Breadcrumb.Item>
//                   <Button
//                     onClick={this.onCancelLevel5}
//                     type={"link"}
//                     style={{ marginTop: 0, padding: 0 }}
//                   >
//                     {contextValues.catalogSearch.level5Name}
//                   </Button>
//                 </Breadcrumb.Item>
//               )}

//               {contextValues.catalogSearch.modelYear !== undefined && (
//                 <Breadcrumb.Item>
//                   <div style={{ margin: "5px", padding: 0 }}>
//                     {contextValues.catalogSearch.modelYear}
//                   </div>
//                 </Breadcrumb.Item>
//               )}
//               {/* {contextValues.catalogSearch.plateNumber !== undefined && (
//                 <Breadcrumb.Item>
//                   <div style={{ margin: "5px", padding: 0 }}>
//                     {contextValues.catalogSearch.plateNumber}
//                   </div>
//                 </Breadcrumb.Item>
//               )} */}
//             </Breadcrumb>
//           )
//         }
//       </BasketContext.Consumer>
//     );
//   }

//   private onCancelLevel2 = () => {
//     let catalogSearch: ICatalogSearch = {
//       ...this.props.catalogSearch,
//       level2Id: undefined,
//       level2Name: undefined,
//       level3Id: undefined,
//       level3Name: undefined,
//       level4Id: undefined,
//       level4Name: undefined,
//       level5Id: undefined,
//       level5Name: undefined,
//       modelYear: undefined,
//     };
//     this.props.setCatalogSearch(catalogSearch);
//     this.props.setSelectedArticle(undefined);
//   };

//   private onCancelLevel3 = () => {
//     let catalogSearch: ICatalogSearch = {
//       ...this.props.catalogSearch,
//       level3Id: undefined,
//       level3Name: undefined,
//       level4Id: undefined,
//       level4Name: undefined,
//       level5Id: undefined,
//       level5Name: undefined,
//       modelYear: undefined,
//     };
//     this.props.setCatalogSearch(catalogSearch);
//     this.props.setSelectedArticle(undefined);
//   };

//   private onCancelLevel4 = () => {
//     let catalogSearch: ICatalogSearch = {
//       ...this.props.catalogSearch,
//       level4Id: undefined,
//       level4Name: undefined,
//       level5Id: undefined,
//       level5Name: undefined,
//       /*modelYear: undefined*/
//     };
//     this.props.setCatalogSearch(catalogSearch);
//     this.props.setSelectedArticle(undefined);
//   };

//   private onCancelLevel5 = () => {
//     let catalogSearch: ICatalogSearch = {
//       ...this.props.catalogSearch,
//       level4Id: undefined,
//       level4Name: undefined,
//       level5Id: undefined,
//       level5Name: undefined,
//       /*modelYear: undefined*/
//     };
//     this.props.setCatalogSearch(catalogSearch);
//     this.props.setSelectedArticle(undefined);
//   };

//   private onCancelSearch = () => {
//     this.props.clearCatalogSearch();
//     this.props.setTypeSearch(0);
//     this.props.setSelectedArticle(undefined);
//     this.props.history.push("/home");
//   };
// }

// export default withRouter(withBasket(CatalogBreadcrumb));
