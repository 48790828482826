import { useEffect, useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Tag, Skeleton, Button } from "antd";

import IArticle from "./IArticle";
import ArticleAvailabilityRequestInformation from "./component/ArticleAvailabilityRequestInformation";
import parse from "html-react-parser";
import { getSession } from "../login/LoginServiceFunctional";
import { findParameterValue } from "../../Utils/ParameterServiceFunctional";
import {
  getAvailabilityColor,
  getAvailabilityText,
  getEans,
  isSupplierAvailability,
  numberFormat,
} from "../../Utils/FlybyUtilsFunctional";

type ArticleAvailabilityProps = {
  item: IArticle;
  loading: boolean;
  showAvailability: boolean;
  showManufacturer: boolean;
  currentSupplierAvailabilityStatus: string;
  currentSupplierAvailabilityLabel: string;
  currentSupplierAvailabilityWarningEnabled: boolean;
  openModalAccount?: () => void;
};

const ArticleAvailability = (props: ArticleAvailabilityProps): JSX.Element => {
  const {
    item,
    loading,
    showAvailability,
    showManufacturer,
    currentSupplierAvailabilityStatus,
    currentSupplierAvailabilityLabel,
    currentSupplierAvailabilityWarningEnabled,
    openModalAccount,
  } = props;

  const [role, setRole] = useState<string>("");
  const [availabilityText1, setAvailabilityText1] = useState<string>(
    "Pour connaitre le prix et la disponibilité vous devez avoir une compte client"
  );
  const [availabilityText2, setAvailabilityText2] = useState<string>(
    "Contactez nous par mail"
  );

  const { Text } = Typography;

  const accessToken = sessionStorage.getItem("accessToken");
  const showPlatformList: boolean =
    sessionStorage.getItem("showPlatformList") === "false" ? false : true;

  useEffect(() => {
    if (accessToken !== null) {
      getSession(accessToken).then((sessionData: any) => {
        setRole(sessionData ? sessionData.user.role.name : "");
      });
    }
    findParameterValue("GUEST_AVAILABILITY_TEXT").then((value: any) => {
      if (value) {
        const text = JSON.parse(value);
        setAvailabilityText1(text.text1);
        setAvailabilityText2(text.text2);
      }
    });
  }, [accessToken, currentSupplierAvailabilityLabel]);

  return (
    <Row style={{ marginLeft: 10, marginRight: 10 }}>
      <Col span={12}>
        {item.availability && (
          <>
            <Tag
              color={getAvailabilityColor(
                currentSupplierAvailabilityStatus &&
                  item.availability.quantityBackorder > 0 &&
                  item.availability.state === "AVAILABLE"
                  ? currentSupplierAvailabilityStatus
                  : item.availability.state,
                item.availability.locallyAvailable,
                item.availability.deliveredAt,
                item.availability.quantityBackorder,
                item.availability.longerAvailabilityAlert,
                item.availability.platform.id
              )}
            >
              {getAvailabilityText(
                item.availability.state,
                item.availability.deliveredAt,
                item.availability.quantityBackorder,
                item.availability.longerAvailabilityAlert,
                currentSupplierAvailabilityLabel &&
                  item.availability.quantityBackorder > 0 &&
                  item.availability.state === "AVAILABLE"
                  ? currentSupplierAvailabilityLabel
                  : undefined
              )}
            </Tag>
            {isSupplierAvailability(
              currentSupplierAvailabilityWarningEnabled &&
                item.availability.quantityBackorder > 0 &&
                item.availability.state === "AVAILABLE"
                ? 10
                : !currentSupplierAvailabilityWarningEnabled &&
                  item.availability.quantityBackorder > 0 &&
                  item.availability.state === "AVAILABLE"
                ? 0
                : item.availability.quantityBackorder
            )}
            <br />
          </>
        )}
        <Text strong>Référence :</Text> {item.reference}
        <br />
        <Text strong>EAN(s) :</Text> {getEans(item.eans)}
        <br />
        {showManufacturer && (
          <>
            <Text strong>Fabricant :</Text> {item.manufacturer.name}
            <br />
          </>
        )}
      </Col>
      <Col span={12}>
        <Skeleton loading={loading} active>
          {item.availability !== undefined && role !== "ROLE_GUEST" && (
            <div key={item.id}>
              <Text strong>Prix unitaire € HT : </Text>
              {numberFormat(item.availability.grossUnitPrice, 2)}
              <br />
              <Text strong>Remise % : </Text>{" "}
              {numberFormat(item.availability.discount, 2)}
              <br />
              <Text strong>Vendu par : </Text>
              {item.availability.salePackaging}
              <br />
              <Text strong>Date de livraison : </Text>
              <ArticleAvailabilityRequestInformation
                articleId={item.id}
                date={item.availability.deliveredAt}
              />
              <br />
              {showPlatformList && item.availability.locallyAvailable && (
                <span>
                  <Text strong>Provenance : </Text>
                  {item.availability.description}
                  <br />
                </span>
              )}
              {showPlatformList && !item.availability.locallyAvailable && (
                <span>
                  <Text strong>Provenance : </Text>
                  <Text strong type="danger">
                    <WarningOutlined /> Autre plateforme
                  </Text>
                  <br />
                </span>
              )}
              {item.availability.consignmentPrice > 0 && (
                <span>
                  <Text strong>Consigne : </Text>
                  {numberFormat(item.availability.consignmentPrice, 2)}
                  <br />
                </span>
              )}
              {showAvailability && (
                <span>
                  <Text strong>Montant net € HT : </Text>
                  {numberFormat(item.availability.netAmount, 2)}
                  <br />
                </span>
              )}
            </div>
          )}
          {item.availability === undefined && role !== "ROLE_GUEST" && (
            <div key={item.id}>
              <Row>
                <Col flex={"50px"}>
                  <Text type="warning">
                    <WarningOutlined style={{ fontSize: 36 }} />
                  </Text>
                </Col>
                <Col flex={"200px"}>
                  <Text type="warning">
                    Impossible d'obtenir la disponibilité depuis la plateforme.
                  </Text>
                </Col>
              </Row>
            </div>
          )}
          {role === "ROLE_GUEST" && (
            <div key={item.id}>
              <Text type="secondary">{parse(availabilityText1)}</Text>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={openModalAccount}
              >
                {parse(availabilityText2)}
              </Button>
            </div>
          )}
        </Skeleton>
      </Col>
    </Row>
  );
};

export default ArticleAvailability;
// class ArticleAvailability extends React.Component<
//   {
//     item: IArticle;
//     loading: boolean;
//     showAvailability: boolean;
//     showManufacturer: boolean;
//     openModalAccount?: () => void;
//   },
//   {
//     role: string;
//     availabilityText1: string;
//     availabilityText2: string;
//     currentAvailabilityState: string;
//     currentAvailabilityLabel: string;
//     currentAvailabilityQuantityBackorder: number;
//   }
// > {
//   private flybyUtils: FlybyUtils = new FlybyUtils();
//   private loginService: LoginService = new LoginService();
//   private parameterService: ParameterService = new ParameterService();
//   private showPlatformList: boolean =
//     sessionStorage.getItem("showPlatformList") === "false" ? false : true;

//   private accessToken = sessionStorage.getItem("accessToken");
//   updateAvailability: boolean = true;

//   constructor(props: {
//     item: IArticle;
//     loading: boolean;
//     showAvailability: boolean;
//     showManufacturer: boolean;
//     openModalAccount?: () => void;
//   }) {
//     super(props);

//     this.state = {
//       role: "",
//       availabilityText1:
//         "Pour connaitre le prix et la disponibilité vous devez avoir une compte client",
//       availabilityText2: "Contactez nous par mail",
//       currentAvailabilityState: "",
//       currentAvailabilityLabel: "",
//       currentAvailabilityQuantityBackorder: -1,
//     };
//   }

//   componentDidMount() {
//     if (this.accessToken !== null) {
//       this.loginService.session(this.accessToken).then((sessionData: any) => {
//         this.setState({
//           role: sessionData ? sessionData.user.role.name : "",
//         });
//       });
//     }
//     this.parameterService
//       .findValue("GUEST_AVAILABILITY_TEXT")
//       .then((value: any) => {
//         if (value) {
//           const text = JSON.parse(value);

//           this.setState({
//             availabilityText1: text.text1,
//             availabilityText2: text.text2,
//           });
//         }
//       });
//   }

//   render() {
//     return (
//       <Row style={{ marginLeft: 10, marginRight: 10 }}>
//         <Col span={12}>
//           {this.props.item.availability && (
//             <>
//               <Tag
//                 color={this.flybyUtils.getAvailabilityColor(
//                   this.state.currentAvailabilityState
//                     ? this.state.currentAvailabilityState
//                     : this.props.item.availability.state,
//                   this.props.item.availability.locallyAvailable,
//                   this.props.item.availability.deliveredAt,
//                   this.props.item.availability.quantityBackorder,
//                   this.props.item.availability.longerAvailabilityAlert,
//                   this.props.item.availability.platform.id
//                 )}
//               >
//                 {this.flybyUtils.getAvailabilityText(
//                   this.props.item.availability.state,
//                   this.props.item.availability.deliveredAt,
//                   this.props.item.availability.quantityBackorder,
//                   this.props.item.availability.longerAvailabilityAlert,
//                   this.state.currentAvailabilityLabel
//                     ? this.state.currentAvailabilityLabel
//                     : undefined
//                 )}
//               </Tag>
//               {this.flybyUtils.isSupplierAvailability(
//                 this.state.currentAvailabilityQuantityBackorder
//                   ? this.state.currentAvailabilityQuantityBackorder
//                   : this.props.item.availability.quantityBackorder
//               )}
//               <br />
//             </>
//           )}
//           <Text strong>Référence :</Text> {this.props.item.reference}
//           <br />
//           <Text strong>EAN(s) :</Text>{" "}
//           {this.flybyUtils.getEans(this.props.item.eans)}
//           <br />
//           {this.props.showManufacturer && (
//             <>
//               <Text strong>Fabricant :</Text>{" "}
//               {this.props.item.manufacturer.name}
//               <br />
//             </>
//           )}
//         </Col>
//         <Col span={12}>
//           <Skeleton loading={this.props.item.loading} active>
//             {this.props.item.availability !== undefined &&
//               this.state.role !== "ROLE_GUEST" && (
//                 <div key={this.props.item.id}>
//                   <Text strong>Prix unitaire € HT : </Text>
//                   {this.flybyUtils.numberFormat(
//                     this.props.item.availability.grossUnitPrice,
//                     2
//                   )}
//                   <br />
//                   <Text strong>Remise % : </Text>{" "}
//                   {this.flybyUtils.numberFormat(
//                     this.props.item.availability.discount,
//                     2
//                   )}
//                   <br />
//                   <Text strong>Vendu par : </Text>
//                   {this.props.item.availability.salePackaging}
//                   <br />
//                   <Text strong>Date de livraison : </Text>
//                   <ArticleAvailabilityRequestInformation
//                     articleId={this.props.item.id}
//                     date={this.props.item.availability.deliveredAt}
//                   />
//                   <br />
//                   {this.showPlatformList &&
//                     this.props.item.availability.locallyAvailable && (
//                       <span>
//                         <Text strong>Provenance : </Text>
//                         {this.props.item.availability.description}
//                         <br />
//                       </span>
//                     )}
//                   {this.showPlatformList &&
//                     !this.props.item.availability.locallyAvailable && (
//                       <span>
//                         <Text strong>Provenance : </Text>
//                         <Text strong type="danger">
//                           <WarningOutlined /> Autre plateforme
//                         </Text>
//                         <br />
//                       </span>
//                     )}
//                   {this.props.item.availability.consignmentPrice > 0 && (
//                     <span>
//                       <Text strong>Consigne : </Text>
//                       {this.flybyUtils.numberFormat(
//                         this.props.item.availability.consignmentPrice,
//                         2
//                       )}
//                       <br />
//                     </span>
//                   )}
//                   {this.props.showAvailability && (
//                     <span>
//                       <Text strong>Montant net € HT : </Text>
//                       {this.flybyUtils.numberFormat(
//                         this.props.item.availability.netAmount,
//                         2
//                       )}
//                       <br />
//                     </span>
//                   )}
//                 </div>
//               )}
//             {this.props.item.availability === undefined &&
//               this.state.role !== "ROLE_GUEST" && (
//                 <div key={this.props.item.id}>
//                   <Row>
//                     <Col flex={"50px"}>
//                       <Text type="warning">
//                         <WarningOutlined style={{ fontSize: 36 }} />
//                       </Text>
//                     </Col>
//                     <Col flex={"200px"}>
//                       <Text type="warning">
//                         Impossible d'obtenir la disponibilité depuis la
//                         plateforme.
//                       </Text>
//                     </Col>
//                   </Row>
//                 </div>
//               )}
//             {this.state.role === "ROLE_GUEST" && (
//               <div key={this.props.item.id}>
//                 <Text type="secondary">
//                   {parse(this.state.availabilityText1)}
//                 </Text>
//                 <Button
//                   type="link"
//                   style={{ padding: 0 }}
//                   onClick={this.props.openModalAccount}
//                 >
//                   {parse(this.state.availabilityText2)}
//                 </Button>
//               </div>
//             )}
//           </Skeleton>
//         </Col>
//       </Row>
//     );
//   }
// }

// export default ArticleAvailability;
