import React from "react";
import { Typography } from "antd";

import IArticle from "../IArticle";
import FlybyUtils from "../../../Utils/FlybyUtils";
import IAvailability from "../IAvailability";
import { MD5 } from "object-hash";
import parse from "html-react-parser";
import { BasketContext } from "../../../Utils/BasketProvider";

const { Text } = Typography;

class MoreAvailabilities extends React.Component<
  { item: IArticle; loading: boolean },
  {}
> {
  private flybyUtils: FlybyUtils = new FlybyUtils();

  render() {
    return (
      <BasketContext.Consumer>
        {(basket) => (
          <>
            {basket.userRole !== "ROLE_GUEST" && (
              <div>
                {this.props.item.availability !== undefined &&
                  !this.props.item.moreAvailabilitiesShow &&
                  this.props.item.availability.comment &&
                  this.props.item.availability.comment
                    .split("\n")
                    .map((line: string) => (
                      <span key={MD5(line)}>
                        {parse(line)}
                        <br />
                      </span>
                    ))}

                {this.props.item.availability !== undefined &&
                  this.props.item.moreAvailabilitiesShow && (
                    <>
                      {this.props.item.moreAvailabilities !== undefined &&
                        this.props.item.moreAvailabilities.length > 0 && (
                          <span>
                            <Text type="warning">Autres disponibilités :</Text>
                            <br />
                          </span>
                        )}

                      {this.props.item.moreAvailabilities !== undefined &&
                        this.props.item.moreAvailabilities.map(
                          (moreAvailability: IAvailability) => (
                            <span key={moreAvailability.platform.id}>
                              {moreAvailability.platform.name}
                              {moreAvailability.deliveredAt && (
                                <>
                                  {" "}
                                  :{" "}
                                  {this.flybyUtils.getAvailabilityText(
                                    moreAvailability.state,
                                    moreAvailability.deliveredAt,
                                    moreAvailability.quantityBackorder,
                                    moreAvailability.longerAvailabilityAlert,
                                    undefined
                                  )}{" "}
                                  le{" "}
                                  {this.flybyUtils.formatJsonDate(
                                    moreAvailability.deliveredAt
                                  )}
                                </>
                              )}
                              <br />
                            </span>
                          )
                        )}
                    </>
                  )}
              </div>
            )}
          </>
        )}
      </BasketContext.Consumer>
    );
  }
}

export default MoreAvailabilities;
