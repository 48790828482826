import React from "react";
import { Table, Typography, List, Tag, Skeleton, Button, Modal } from "antd";
import { ColumnProps } from "antd/lib/table";
import hash from "object-hash";

import { withBasket, BasketContext } from "../../Utils/BasketProvider";
import IArticle from "./IArticle";
import FlybyUtils from "../../Utils/FlybyUtils";
import IData from "../../Utils/IData";
import IArticlePlatforms from "./IArticlePlatforms";
import ArticleService from "./ArticleService";
import ArticleUtils from "./ArticleUtils";
import ArticleCard from "./ArticleCard";
import IArticleSearchResult from "./IArticleSearchResult";
import ICatalogSearch from "../catalog/model/ICatalogSearch";
import ParameterService from "../../Utils/ParameterService";
import AdvancedSearch from "./AdvancedSearch";
import IFilterQuery from "../../Utils/IFilterQuery";
import { WarningOutlined } from "@ant-design/icons";
import ArticleWishlistForm from "./ArticleWishlistForm";
import DetailArticle from "./component/DetailArticle";
import OpenAccount from "../login/OpenAccount";

const { Text } = Typography;

class ArticleDisplay extends React.Component<
  {
    addArticle: any;
    setQuery: any;
    setAdvancedQuery: any;
    query: string;
    advancedQuery: boolean;
    setTypeSearch: any;
    typeSearch: number;
    catalogSearch: ICatalogSearch;
    data: IData;
    filterQuery: IFilterQuery;
    articleView: string;
    selectedArticle: IArticle | undefined;
    setSelectedArticle: any;
  },
  {
    data: IData;
    dataLoading: boolean;
    openModalAccount: boolean;
  }
> {
  private articleUtils: ArticleUtils = new ArticleUtils();
  private articleService: ArticleService = new ArticleService();
  private parameterService: ParameterService = new ParameterService();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private pageSizeList: number = 5;
  private pageSizeTable: number = 10;
  private infosTecdoc: boolean = false;
  private infosCatalogF1: boolean = false;

  private showPlatformList: boolean =
    sessionStorage.getItem("showPlatformList") === "false" ? false : true;

  private columns: ColumnProps<IArticleSearchResult>[] = [
    {
      title: "Référence",
      dataIndex: "article.reference",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          <Button type="link" onClick={() => this.onSelectedArticle(item)}>
            {item.article.reference}
          </Button>
        </div>
      ),
      width: 120,
    },
    {
      title: "Infos",
      dataIndex: "infos",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined && (
            <div>
              <Tag
                color={this.flybyUtils.getAvailabilityColor(
                  item.article.availability.state,
                  item.article.availability.locallyAvailable,
                  item.article.availability.deliveredAt,
                  item.article.availability.quantityBackorder,
                  item.article.availability.longerAvailabilityAlert,
                  item.article.availability.platform.id
                )}
              >
                {this.flybyUtils.getAvailabilityText(
                  item.article.availability.state,
                  item.article.availability.deliveredAt,
                  item.article.availability.quantityBackorder,
                  item.article.availability.longerAvailabilityAlert,
                  undefined
                )}
              </Tag>
              {this.flybyUtils.isSupplierAvailability(
                item.article.availability.quantityBackorder
              )}
              <br />
            </div>
          )}
          <Text strong>Fabricant :</Text> {item.article.manufacturer.name}
          <br />
          <Text strong>EAN(s) :</Text>{" "}
          {this.flybyUtils.getEans(item.article.eans)}
          <br />
          {item.article.availability !== undefined && (
            <div>
              <Text strong>Date de livraison : </Text>
              {this.flybyUtils.formatDeliveredAt(
                item.article.availability.deliveredAt
              )}
              <br />
              {this.showPlatformList &&
                item.article.availability.locallyAvailable && (
                  <span>
                    <Text strong>Provenance : </Text>{" "}
                    {item.article.availability.description}
                  </span>
                )}
              {this.showPlatformList &&
                !item.article.availability.locallyAvailable && (
                  <span>
                    <Text strong>Provenance : </Text>{" "}
                    <Text strong type="danger">
                      <WarningOutlined /> Autre plateforme
                    </Text>
                  </span>
                )}
            </div>
          )}
        </div>
      ),
      width: 300,
    },
    {
      title: "Prix unitaire € HT",
      dataIndex: "article.availability.grossUnitPrice",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? this.flybyUtils.numberFormat(
                item.article.availability.grossUnitPrice,
                2
              )
            : ""}
        </div>
      ),
      align: "right",
      width: 110,
    },
    {
      title: "Remise % ",
      dataIndex: "article.availability.discount",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? this.flybyUtils.numberFormat(
                item.article.availability.discount,
                2
              )
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Vendu par",
      dataIndex: "article.availability.salePackaging",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? item.article.availability.salePackaging
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Consigne",
      dataIndex: "article.availability.consignmentPrice",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? this.flybyUtils.numberFormat(
                item.article.availability.consignmentPrice,
                2
              )
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Plateforme",
      dataIndex: "platform",
      render: (text: string, item: IArticleSearchResult) => (
        <Skeleton loading={item.article.loading} active>
          {item.article.availability !== undefined && (
            <ArticleWishlistForm
              item={item.article}
              onSelectPlatform={(value: any) =>
                this.onChangeSelect(value, item.article)
              }
              onChangeQuantity={this.onChangeQuantity}
            />
          )}
          {item.article.availability === undefined && (
            <Text type="warning">
              Impossible d'obtenir la disponibilité depuis la plateforme.
            </Text>
          )}
        </Skeleton>
      ),
      width: this.showPlatformList ? 350 : 200,
    },
    {
      title: "Montant net € HT",
      dataIndex: "article.availability.netAmount",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? this.flybyUtils.numberFormat(
                item.article.availability.netAmount,
                2
              )
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
    {
      title: "Montant € TTC",
      dataIndex: "article.availability.netAmountInclTaxes",
      render: (text: string, item: IArticleSearchResult) => (
        <div>
          {item.article.availability !== undefined
            ? this.flybyUtils.numberFormat(
                item.article.availability.netAmountInclTaxes,
                2
              )
            : ""}
        </div>
      ),
      align: "right",
      width: 105,
    },
  ];

  constructor(props: {
    addArticle: any;
    setQuery: any;
    setAdvancedQuery: any;
    query: string;
    advancedQuery: boolean;
    setTypeSearch: any;
    typeSearch: number;
    data: IData;
    catalogSearch: ICatalogSearch;
    filterQuery: IFilterQuery;
    articleView: string;
    selectedArticle: IArticle | undefined;
    setSelectedArticle: any;
  }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: false,
      openModalAccount: false,
    };
  }

  componentDidMount() {
    let promise1 = this.parameterService
      .findValue("TECDOC_SHOW_INFOS")
      .then((value: string) => {
        if (value !== undefined) {
          return JSON.parse(value);
        } else {
          return false;
        }
      });

    let promise2 = this.parameterService
      .findValue("CATALOG_F1")
      .then((value: string) => {
        if (value !== undefined) {
          return JSON.parse(value);
        } else {
          return false;
        }
      });

    Promise.all([promise1, promise2]).then((result: any) => {
      this.infosTecdoc = result[0];
      this.infosCatalogF1 = result[1];

      switch (this.props.typeSearch) {
        //query search
        case 1:
          this.searchArticles(
            this.props.query,
            this.props.filterQuery,
            this.pageSize(this.props.articleView),
            0
          );
          break;
        //catalog F1
        case 3:
          if (this.props.catalogSearch.queryType === "vehicleNode") {
            this.searchCatalogArticles(
              this.props.catalogSearch.level3Id,
              this.props.catalogSearch.level5Id,
              this.props.catalogSearch.modelYear,
              this.pageSize(this.props.articleView),
              0
            );
          }

          if (this.props.catalogSearch.queryType === "characteristic") {
            this.searchCatalogArticles(
              this.props.catalogSearch.level3Id,
              this.props.catalogSearch.level5Id,
              undefined,
              this.pageSize(this.props.articleView),
              0
            );
          }

          if (this.props.catalogSearch.queryType === "family") {
            this.searchCatalogArticles(
              undefined,
              this.props.catalogSearch.level2Id,
              this.props.catalogSearch.modelYear,
              this.pageSize(this.props.articleView),
              0
            );
          }
          break;
        //ETAI search
        case 4:
          this.searchArticlesFromPreviousList(this.props.data);
          break;
      }
    });
  }

  componentWillReceiveProps(newProps: {
    addArticle: any;
    setQuery: any;
    setAdvancedQuery: any;
    query: string;
    advancedQuery: boolean;
    setTypeSearch: any;
    typeSearch: number;
    data: IData;
    catalogSearch: ICatalogSearch;
    filterQuery: IFilterQuery;
    articleView: string;
    setArticleView: any;
    selectedArticle: IArticle | undefined;
    setSelectedArticle: any;
  }) {
    switch (newProps.typeSearch) {
      case 1:
        //|| (newProps.query === this.props.query && hash.MD5(newProps.filterQuery) !== hash.MD5(this.props.filterQuery))
        if (
          newProps.query !== this.props.query ||
          newProps.articleView !== this.props.articleView ||
          hash.MD5(this.props.filterQuery) !== hash.MD5(newProps.filterQuery)
        ) {
          this.searchArticles(
            newProps.query,
            newProps.filterQuery,
            this.pageSize(newProps.articleView),
            0
          );
        }
        break;

      case 3:
        if (
          newProps.catalogSearch.queryType === "vehicleNode" &&
          (hash.MD5(newProps.catalogSearch) !==
            hash.MD5(this.props.catalogSearch) ||
            newProps.articleView !== this.props.articleView)
        ) {
          this.searchCatalogArticles(
            newProps.catalogSearch.level3Id,
            newProps.catalogSearch.level5Id,
            newProps.catalogSearch.modelYear,
            this.pageSize(newProps.articleView),
            0
          );
        }

        if (
          newProps.catalogSearch.queryType === "characteristic" &&
          (hash.MD5(newProps.catalogSearch) !==
            hash.MD5(this.props.catalogSearch) ||
            newProps.articleView !== this.props.articleView)
        ) {
          this.searchCatalogArticles(
            newProps.catalogSearch.level3Id,
            newProps.catalogSearch.level5Id,
            undefined,
            this.pageSize(newProps.articleView),
            0
          );
        }

        if (
          newProps.catalogSearch.queryType === "family" &&
          (hash.MD5(newProps.catalogSearch) !==
            hash.MD5(this.props.catalogSearch) ||
            newProps.articleView !== this.props.articleView)
        ) {
          this.searchCatalogArticles(
            undefined,
            newProps.catalogSearch.level2Id,
            newProps.catalogSearch.modelYear,
            this.pageSize(newProps.articleView),
            0
          );
        }

        break;

      case 4:
        if (
          hash.MD5(newProps.data) !== hash.MD5(this.props.data) ||
          newProps.typeSearch !== this.props.typeSearch ||
          newProps.articleView !== this.props.articleView
        ) {
          this.searchArticlesFromPreviousList(newProps.data);
        }
        break;
    }
  }

  render() {
    return (
      <div>
        <BasketContext.Consumer>
          {(value) => (
            <>
              {this.props.advancedQuery && this.props.typeSearch === 1 && (
                <AdvancedSearch
                  onSearch={this.onFilter}
                  totalRecordFound={this.state.data.totalElements}
                />
              )}

              <div style={{ width: "100%" }} id="itemsDiv">
                {this.props.selectedArticle === undefined && (
                  <>
                    {!this.props.advancedQuery && (
                      <Text style={{ marginBottom: 15 }}>
                        Nombre de résultats trouvés :{" "}
                        {this.state.data.totalElements}
                      </Text>
                    )}

                    {value.articleView === "table" && (
                      <Table
                        rowKey="article"
                        loading={this.state.dataLoading}
                        style={{ overflowX: "auto" }}
                        columns={this.columns}
                        showHeader={true}
                        bordered={true}
                        dataSource={this.state.data.content}
                        onChange={this.onChange}
                        pagination={{
                          current: this.state.data.number + 1,
                          pageSize: this.state.data.size,
                          total: this.state.data.totalElements,
                        }}
                      />
                    )}

                    {value.articleView === "list" && (
                      <List
                        loading={this.state.dataLoading}
                        grid={{ gutter: 8, column: 1 }}
                        dataSource={this.state.data.content}
                        pagination={{
                          onChange: this.onChangeList,
                          current: this.state.data.number + 1,
                          pageSize: this.state.data.size,
                          total: this.state.data.totalElements,
                        }}
                        renderItem={(item: IArticleSearchResult) => (
                          <List.Item>
                            <ArticleCard
                              item={item}
                              onChangeSelect={this.onChangeSelect}
                              onChangeQuantity={this.onChangeQuantity}
                              onSelectEquivalence={this.onSelectEquivalence}
                              isModal={false}
                              infosTecdoc={this.infosTecdoc}
                              infosCatalogF1={this.infosCatalogF1}
                              openModalAccount={this.onOpenModalAccount}
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </>
                )}

                {this.props.selectedArticle !== undefined && (
                  <DetailArticle
                    article={this.props.selectedArticle}
                    onChangeSelect={this.onChangeSelect}
                    onChangeQuantity={this.onChangeQuantity}
                  />
                )}
              </div>
            </>
          )}
        </BasketContext.Consumer>

        <Modal
          open={this.state.openModalAccount}
          destroyOnClose
          closable={false}
          title="Demande d'ouverture d'un compte"
          style={{ top: 20 }}
          width={450}
          bodyStyle={{ height: 650 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <OpenAccount closeModal={this.onCloseModalAccount} />
        </Modal>
      </div>
    );
  }

  private onOpenModalAccount = () => {
    this.setState({
      openModalAccount: true,
    });
  };

  private onCloseModalAccount = () => {
    this.setState({
      openModalAccount: false,
    });
  };

  private onChangeList = (pageNumber: number, pageSize?: number) => {
    if (this.props.typeSearch === 3) {
      this.searchCatalogArticles(
        this.props.catalogSearch.level3Id,
        this.props.catalogSearch.level5Id,
        this.props.catalogSearch.modelYear,
        this.pageSize(this.props.articleView),
        pageNumber - 1
      );
    } else {
      this.searchArticles(
        this.props.query,
        this.props.filterQuery,
        this.pageSize(this.props.articleView),
        pageNumber - 1
      );
    }
  };

  private onFilter = () => {
    this.searchArticles(
      this.props.query,
      this.props.filterQuery,
      this.pageSize(this.props.articleView),
      0
    );
  };

  private searchArticlesFromPreviousList = (data: IData) => {
    this.setState({
      dataLoading: true,
    });

    if (data.size === 0) data.size = this.pageSize(this.props.articleView);

    this.search("", data);
  };

  private onChangeQuantity = (
    quantity: number,
    platformId: number,
    item: IArticle
  ) => {
    this.articleUtils.onChangeQuantity(this, quantity, platformId, item);
  };

  private onChangeSelect = (platformId: number, item: IArticle) => {
    this.articleUtils.onChangeSelect(this, platformId, item);
  };

  private onChange = (
    pagination: any,
    filters: Record<never, string[]>,
    sorter: any,
    extra: any
  ) => {
    pagination = this.flybyUtils.getCurrentPage(pagination);

    if (this.props.typeSearch === 3) {
      this.searchCatalogArticles(
        this.props.catalogSearch.level3Id,
        this.props.catalogSearch.level5Id,
        this.props.catalogSearch.modelYear,
        this.pageSize(this.props.articleView),
        pagination.current
      );
    } else {
      this.searchArticles(
        this.props.query,
        this.props.filterQuery,
        this.pageSize(this.props.articleView),
        pagination.current
      );
    }
  };

  private onSelectEquivalence = (articleId: number) => {
    this.setState({
      dataLoading: true,
    });

    this.articleService.searchArticleById(articleId).then((result: any) => {
      if (result !== undefined) {
        let quantity = 1;
        if (result.content[0].article.articlePlatforms) {
          quantity =
            result.content[0].article.articlePlatforms[0].salePackaging;
        }

        result.content[0].article.loading = true;
        result.content[0].article.quantity = quantity;
        result.content[0].article.equivalence = {
          loading: true,
          list: [],
        };

        this.articleUtils.checkArticleAvailability(
          this,
          undefined,
          result.content[0].article,
          false
        );
        this.articleUtils.findEquivalences(
          this,
          result.content[0].article,
          true
        );

        this.setState({
          data: result,
          dataLoading: false,
        });
      }
    });
  };

  private searchArticles = (
    query: string,
    filterQuery: IFilterQuery,
    pageSize: number | undefined,
    current: number | undefined
  ) => {
    this.setState({
      dataLoading: true,
    });

    this.articleService
      .searchs(query, filterQuery, pageSize, current)
      .then((data: any) => {
        if (data !== undefined) {
          this.search(query, data);
          this.props.setTypeSearch(1);
        }
      });
  };

  private searchCatalogArticles = (
    vehicleTypeId: number | undefined,
    familyId: number | undefined,
    modelYear: number | undefined,
    pageSize: number | undefined,
    current: number | undefined
  ) => {
    this.setState({
      dataLoading: true,
    });

    this.articleService
      .searchCatalogArticle(
        vehicleTypeId,
        familyId,
        modelYear,
        pageSize,
        current
      )
      .then((data: any) => {
        if (data !== undefined) {
          this.search("", data);
          this.props.setTypeSearch(3);
          document?.getElementById("itemsDiv")?.scrollIntoView();
        }
      });
  };

  private search = (query: string, data: any) => {
    if (data !== undefined && data.content !== undefined) {
      data.content.forEach((articleSearchResult: IArticleSearchResult) => {
        const { article } = articleSearchResult;

        let articlePlatform: IArticlePlatforms = article.articlePlatforms[0];

        if (articlePlatform !== undefined) {
          article.loading = true;
          article.quantity = articlePlatform.salePackaging;
          article.equivalence = {
            loading: true,
            list: [],
          };

          if (articleSearchResult.known) {
            this.articleUtils.checkArticleAvailability(
              this,
              undefined,
              article,
              false
            );
            this.articleUtils.findEquivalences(this, article, true);
            document?.getElementById("itemsDiv")?.scrollIntoView();
          }
        }
      });

      this.setState({
        data: data,
        dataLoading: false,
      });

      this.props.setQuery(query);
    } else {
      this.setState({
        dataLoading: false,
      });
    }
  };

  private pageSize = (articleView: string) => {
    if (articleView === "table") {
      return this.pageSizeTable;
    }
    if (articleView === "list") {
      return this.pageSizeList;
    }

    return 10;
  };

  private onSelectedArticle = (articleSearchResult: IArticleSearchResult) => {
    this.props.setSelectedArticle(articleSearchResult.article);
  };
}

export default withBasket(ArticleDisplay);
