import { useCallback, useState } from "react";
import "../../App.css";
import {
  CheckSquareFilled,
  CloseSquareFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Select,
  InputNumber,
  Button,
  Row,
  Col,
  Modal,
  Input,
  Typography,
} from "antd";

import { BasketContext, withBasket } from "../../Utils/BasketProvider";
import IArticle from "./IArticle";
import SuggestedQuantityForm from "./SuggestedQuantityForm";
import IAvailability from "./IAvailability";
import {
  formatJsonDate,
  getAvailabilityColor,
} from "../../Utils/FlybyUtilsFunctional";

type ArticleWishlistFormProps = {
  addArticle: any;
  item: IArticle;
  currentSupplierAvailabilityStatus: string;
  onSelectPlatform: (platformId: number, item: IArticle) => void;
  onChangeQuantity: (
    quantity: number,
    platformId: number,
    item: IArticle
  ) => void;
};

const ArticleWishlistForm = (props: ArticleWishlistFormProps): JSX.Element => {
  const {
    addArticle,
    item,
    currentSupplierAvailabilityStatus,
    onSelectPlatform,
    onChangeQuantity,
  } = props;
  const [showSuggestedQuantityForm, setShowSuggestedQuantityForm] =
    useState<boolean>(false);
  const [showAddToBasketButton, setShowAddToBasketButton] =
    useState<boolean>(false);

  const Option = Select.Option;
  const { Text } = Typography;
  let timer: any = null;
  const showPlatformList: boolean =
    sessionStorage.getItem("showPlatformList") === "false" ? false : true;

  const onSuggestedQuantityOk = useCallback(async () => {
    if (item.availability !== undefined) {
      let quantity =
        item.availability.quantityOrdered === undefined
          ? item.availability.suggestedQuantity
          : item.availability.quantityOrdered;
      if (quantity > 0) {
        await addArticle(item, quantity, item.availability.platform.id);
      }
    }

    if (item.moreAvailabilities !== undefined) {
      item.moreAvailabilities.forEach(async (availability: IAvailability) => {
        let quantity =
          availability.quantityOrdered === undefined
            ? 0
            : availability.quantityOrdered;
        if (quantity > 0) {
          await addArticle(item, quantity, availability.platform.id);
        }
      });
    }
    setShowSuggestedQuantityForm(false);
  }, [addArticle, item]);

  const onSuggestedQuantityCancel = useCallback(() => {
    setShowSuggestedQuantityForm(false);
  }, []);

  const addToBasket = useCallback(
    async (item: IArticle) => {
      setShowAddToBasketButton(true);
      if (item.availability !== undefined) {
        if (item.moreAvailabilitiesShow) {
          setShowSuggestedQuantityForm(true);
        } else {
          await addArticle(item, item.quantity, item.availability.platform.id);
        }
      }
      setShowAddToBasketButton(false);
    },
    [addArticle]
  );

  const onChangeCurrentQuantity = (value: number | null, item: IArticle) => {
    if (value && value > 0) {
      item.quantity = value;

      clearTimeout(timer);

      timer = setTimeout(() => {
        if (item.availability !== undefined) {
          onChangeQuantity(item.quantity, item.availability.platform.id, item);
        }
      }, 500);
    }
  };

  const onChangeSelect = useCallback(
    (value: any, item: any) => {
      onSelectPlatform(value, item);
    },
    [onSelectPlatform]
  );

  return (
    <BasketContext.Consumer>
      {(basket) => (
        <>
          {basket.userRole !== "ROLE_GUEST" && (
            <Row style={{ textAlign: "left" }}>
              {item.listAvailabilities !== undefined &&
                item.availability !== undefined && (
                  <>
                    <Col span={24}>
                      <Input.Group compact>
                        {showPlatformList && (
                          <Select
                            placeholder="Plateforme"
                            defaultValue={item.availability.platform.id}
                            style={{ width: 190 }}
                            onChange={(value: any) =>
                              onChangeSelect(value, item)
                            }
                            dropdownMatchSelectWidth={370}
                          >
                            {item.listAvailabilities.map(
                              (availability: IAvailability) => (
                                <Option
                                  value={availability.platform.id}
                                  key={availability.platform.id.toString()}
                                >
                                  {availability.deliveredAt !== undefined &&
                                    availability.deliveredAt !== "" &&
                                    availability.deliveredAt !== null && (
                                      <>
                                        <CheckSquareFilled
                                          style={{
                                            color: getAvailabilityColor(
                                              currentSupplierAvailabilityStatus &&
                                                availability.quantityBackorder >
                                                  0 &&
                                                availability.state ===
                                                  "AVAILABLE"
                                                ? currentSupplierAvailabilityStatus
                                                : availability.state,
                                              // availability.state,
                                              availability.locallyAvailable,
                                              availability.deliveredAt,
                                              availability.quantityBackorder,
                                              availability.longerAvailabilityAlert
                                            ),
                                          }}
                                        />{" "}
                                        {availability.platform.name} -{" "}
                                        {formatJsonDate(
                                          availability.deliveredAt
                                        )}
                                      </>
                                    )}
                                  {(availability.deliveredAt === undefined ||
                                    availability.deliveredAt === "" ||
                                    availability.deliveredAt === null) && (
                                    <>
                                      <CloseSquareFilled
                                        style={{
                                          color: getAvailabilityColor(
                                            currentSupplierAvailabilityStatus &&
                                              availability.quantityBackorder >
                                                0 &&
                                              availability.state === "AVAILABLE"
                                              ? currentSupplierAvailabilityStatus
                                              : availability.state,
                                            availability.locallyAvailable,
                                            availability.deliveredAt,
                                            availability.quantityBackorder,
                                            availability.longerAvailabilityAlert
                                          ),
                                        }}
                                      />{" "}
                                      {availability.platform.name}
                                    </>
                                  )}
                                </Option>
                              )
                            )}
                          </Select>
                        )}
                        <InputNumber
                          min={item.availability.salePackaging}
                          step={item.availability.salePackaging}
                          value={item.quantity}
                          onChange={(value) =>
                            onChangeCurrentQuantity(value, item)
                          }
                        />
                        <Button
                          loading={showAddToBasketButton}
                          disabled={item.loading}
                          onClick={() => addToBasket(item)}
                          style={{ paddingTop: 8 }}
                        >
                          <ShoppingCartOutlined />
                        </Button>
                      </Input.Group>
                      {item.availability.platformUnreachable && (
                        <Text type="warning">
                          La demande n’a pas pu aboutir,
                          <br />
                          merci de réessayer ou de contacter votre plateforme.
                        </Text>
                      )}
                    </Col>
                  </>
                )}

              <Modal
                title="Notre suggestion de commande"
                open={showSuggestedQuantityForm}
                okText={"Valider"}
                cancelText={"Annuler"}
                onOk={onSuggestedQuantityOk}
                onCancel={onSuggestedQuantityCancel}
                destroyOnClose={true}
                bodyStyle={{
                  height: 320,
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
                width={800}
              >
                <SuggestedQuantityForm item={item} />
              </Modal>
            </Row>
          )}
        </>
      )}
    </BasketContext.Consumer>
  );
};

export default withBasket(ArticleWishlistForm);

// class ArticleWishlistForm extends React.Component<
//   {
//     addArticle: any;
//     item: IArticle;
//     onSelectPlatform: any;
//     onChangeQuantity: any;
//   },
//   {
//     showSuggestedQuantityForm: boolean;
//   }
// > {
//   private timer: any = null;
//   private showPlatformList: boolean =
//     sessionStorage.getItem("showPlatformList") === "false" ? false : true;
//   private flybyUtils: FlybyUtils = new FlybyUtils();

//   constructor(props: {
//     addArticle: any;
//     item: IArticle;
//     onSelectPlatform: any;
//     onChangeQuantity: any;
//   }) {
//     super(props);

//     this.state = {
//       showSuggestedQuantityForm: false,
//     };
//   }

//   render() {
//     return (
//       <BasketContext.Consumer>
//         {(basket) => (
//           <>
//             {basket.userRole !== "ROLE_GUEST" && (
//               <Row style={{ textAlign: "left" }}>
//                 {this.props.item.listAvailabilities !== undefined &&
//                   this.props.item.availability !== undefined && (
//                     <>
//                       <Col span={24}>
//                         <Input.Group compact>
//                           {this.showPlatformList && (
//                             <Select
//                               placeholder="Plateforme"
//                               defaultValue={
//                                 this.props.item.availability.platform.id
//                               }
//                               style={{ width: 190 }}
//                               onChange={(value: any) =>
//                                 this.onChangeSelect(value, this.props.item)
//                               }
//                               dropdownMatchSelectWidth={370}
//                             >
//                               {this.props.item.listAvailabilities.map(
//                                 (availability: IAvailability) => (
//                                   <Option
//                                     value={availability.platform.id}
//                                     key={availability.platform.id.toString()}
//                                   >
//                                     {availability.deliveredAt !== undefined &&
//                                       availability.deliveredAt !== "" &&
//                                       availability.deliveredAt !== null && (
//                                         <>
//                                           <CheckSquareFilled
//                                             style={{
//                                               color:
//                                                 this.flybyUtils.getAvailabilityColor(
//                                                   availability.state,
//                                                   availability.locallyAvailable,
//                                                   availability.deliveredAt,
//                                                   availability.quantityBackorder,
//                                                   availability.longerAvailabilityAlert
//                                                 ),
//                                             }}
//                                           />{" "}
//                                           {availability.platform.name} -{" "}
//                                           {this.flybyUtils.formatJsonDate(
//                                             availability.deliveredAt
//                                           )}
//                                         </>
//                                       )}
//                                     {(availability.deliveredAt === undefined ||
//                                       availability.deliveredAt === "" ||
//                                       availability.deliveredAt === null) && (
//                                       <>
//                                         <CloseSquareFilled
//                                           style={{
//                                             color:
//                                               this.flybyUtils.getAvailabilityColor(
//                                                 availability.state,
//                                                 availability.locallyAvailable,
//                                                 availability.deliveredAt,
//                                                 availability.quantityBackorder,
//                                                 availability.longerAvailabilityAlert
//                                               ),
//                                           }}
//                                         />{" "}
//                                         {availability.platform.name}
//                                       </>
//                                     )}
//                                   </Option>
//                                 )
//                               )}
//                             </Select>
//                           )}
//                           <InputNumber
//                             min={this.props.item.availability.salePackaging}
//                             step={this.props.item.availability.salePackaging}
//                             value={this.props.item.quantity}
//                             onChange={(value) =>
//                               this.onChangeQuantity(value, this.props.item)
//                             }
//                           />
//                           <Button
//                             disabled={this.props.item.loading}
//                             onClick={() => this.addToBasket(this.props.item)}
//                             style={{ paddingTop: 8 }}
//                           >
//                             <ShoppingCartOutlined />
//                           </Button>
//                         </Input.Group>
//                         {this.props.item.availability.platformUnreachable && (
//                           <Text type="warning">
//                             La demande n’a pas pu aboutir,
//                             <br />
//                             merci de réessayer ou de contacter votre plateforme.
//                           </Text>
//                         )}
//                       </Col>
//                     </>
//                   )}

//                 <Modal
//                   title="Notre suggestion de commande"
//                   visible={this.state.showSuggestedQuantityForm}
//                   okText={"Valider"}
//                   cancelText={"Annuler"}
//                   onOk={this.onSuggestedQuantityOk}
//                   onCancel={this.onSuggestedQuantityCancel}
//                   destroyOnClose={true}
//                   bodyStyle={{
//                     height: 320,
//                     overflowX: "hidden",
//                     overflowY: "auto",
//                   }}
//                   width={800}
//                 >
//                   <SuggestedQuantityForm item={this.props.item} />
//                 </Modal>
//               </Row>
//             )}
//           </>
//         )}
//       </BasketContext.Consumer>
//     );
//   }

//   private onSuggestedQuantityOk = () => {
//     if (this.props.item.availability !== undefined) {
//       let quantity =
//         this.props.item.availability.quantityOrdered === undefined
//           ? this.props.item.availability.suggestedQuantity
//           : this.props.item.availability.quantityOrdered;
//       if (quantity > 0) {
//         this.props.addArticle(
//           this.props.item,
//           quantity,
//           this.props.item.availability.platform.id
//         );
//       }
//     }

//     if (this.props.item.moreAvailabilities !== undefined) {
//       this.props.item.moreAvailabilities.forEach(
//         (availability: IAvailability) => {
//           let quantity =
//             availability.quantityOrdered === undefined
//               ? 0
//               : availability.quantityOrdered;
//           if (quantity > 0) {
//             this.props.addArticle(
//               this.props.item,
//               quantity,
//               availability.platform.id
//             );
//           }
//         }
//       );
//     }

//     this.setState({ showSuggestedQuantityForm: false });
//   };

//   private onSuggestedQuantityCancel = () => {
//     this.setState({ showSuggestedQuantityForm: false });
//   };

//   private addToBasket = (item: IArticle) => {
//     if (item.availability !== undefined) {
//       if (item.moreAvailabilitiesShow) {
//         this.setState({ showSuggestedQuantityForm: true });
//       } else {
//         this.props.addArticle(
//           item,
//           item.quantity,
//           item.availability.platform.id
//         );
//       }
//     }
//   };

//   private onChangeQuantity = (value: number | null, item: IArticle) => {
//     if (value && value > 0) {
//       item.quantity = value;

//       clearTimeout(this.timer);

//       this.timer = setTimeout(() => {
//         if (this.props.item.availability !== undefined) {
//           this.props.onChangeQuantity(
//             item.quantity,
//             this.props.item.availability.platform.id,
//             item
//           );
//         }
//       }, 500);
//     }
//   };

//   private onChangeSelect = (value: any, item: any) => {
//     this.props.onSelectPlatform(value, item);
//   };
// }

// export default withBasket(ArticleWishlistForm);
