import React, { useCallback, useEffect, useState } from "react";
import { Divider, Row, Col } from "antd";
import { useMarketParameter } from "../Utils/MarketParameterContext";
import { getPublicHtmlPage } from "../Utils/ParameterServiceFunctional";

const { version } = require("../../package.json");

const footerJs = (modal: any, btn: any, span: any) => {
  if (modal && btn && span) {
    // When the user clicks the button, open the modal
    btn.onclick = function () {
      modal.style.display = "block";
    };

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event: any) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  }
};

// interface parameter {
//   name: string;

//   value: string;
// }

const Footer = (props: { isAuthenticated?: number }): React.ReactElement => {
  const { isAuthenticated } = props;
  const { APP_FOOTER, APP_FOOTER_LOGIN } = useMarketParameter();
  const [page, setPage] = useState<string>("");
  // const contextValues = useContext(MarketParameterContext);
  // const { APP_FOOTER_LOGIN } = contextValues;

  const formatFooter = useCallback((response: string): void => {
    setPage(response);
    var modal1: any = document.getElementById("myModal1");
    var btn1: any = document.getElementById("myBtn1");
    var close1: any = document.getElementById("close1");
    footerJs(modal1, btn1, close1);
    var modal2: any = document.getElementById("myModal2");
    var btn2: any = document.getElementById("myBtn2");
    var close2: any = document.getElementById("close2");
    footerJs(modal2, btn2, close2);
    var modal3: any = document.getElementById("myModal3");
    var btn3: any = document.getElementById("myBtn3");
    var close3: any = document.getElementById("close3");
    footerJs(modal3, btn3, close3);
    var modal4: any = document.getElementById("myModal4");
    var btn4: any = document.getElementById("myBtn4");
    var close4: any = document.getElementById("close4");
    footerJs(modal4, btn4, close4);
  }, []);

  const initFooter = useCallback(async (): Promise<void> => {
    if (APP_FOOTER_LOGIN && APP_FOOTER_LOGIN !== "INC" && !isAuthenticated) {
      getPublicHtmlPage(APP_FOOTER_LOGIN)
        .then((response: string) => {
          formatFooter(response);
        })
        .catch((error) => {
          setPage("");
        });
    }
    if (APP_FOOTER !== "INC" && isAuthenticated) {
      getPublicHtmlPage(APP_FOOTER)
        .then((response: string) => {
          formatFooter(response);
        })
        .catch((error) => {
          setPage("");
        });
    }
    // }
  }, [APP_FOOTER, APP_FOOTER_LOGIN, formatFooter, isAuthenticated]);

  // Requête pour récupérer le contenu de ld fichier .html
  useEffect(() => {
    initFooter();
  }, [initFooter]);

  return (
    <div style={{ marginTop: 20, marginBottom: 10 }}>
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: page }}></div>
      <Row>
        <Col span={24} style={{ marginTop: 20, textAlign: "center" }}>
          v{version} - Conception & Réalisation{" "}
          <a
            href="http://www.inconcept.fr"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={"/api/public/Logo-In-Concept.png"}
              alt="IN Concept"
              style={{ height: 25 }}
            />
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
