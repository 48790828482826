import { useCallback, useEffect, useState } from "react";
import "../../App.css";
import { Row, Col, Input, Button, Radio } from "antd";

import { BasketContext, withBasket } from "../../Utils/BasketProvider";
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  TableOutlined,
  BarsOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { RadioChangeEvent } from "antd/lib/radio";
import { getSession } from "../login/LoginServiceFunctional";
import IFilterQuery from "../../Utils/IFilterQuery";

type HeadbandSearchProps = {
  showEtai: boolean;
  showTecdoc: boolean;
  title: string | undefined;
  onSearchArticle: () => void;
  onAdvancedQuery: (advancedQuery: boolean) => void;
  onSearchEtai: () => void;
  cancelSearch: () => void;
  onChangeQuery: () => void;
  setArticleView: (view: string) => void;
  setFilterQuery: (filterQuery: IFilterQuery) => void;
};

const HeadbandSearch = (props: HeadbandSearchProps): JSX.Element => {
  const {
    showEtai,
    showTecdoc,
    onSearchArticle,
    onAdvancedQuery,
    onSearchEtai,
    cancelSearch,
    // onChangeQuery,
    setArticleView,
    setFilterQuery,
    title,
  } = props;

  const [role, setRole] = useState<string>("");

  const accessToken = sessionStorage.getItem("accessToken");

  const Search = Input.Search;

  const setAdvancedQuery = useCallback(
    (value: any) => {
      setFilterQuery({
        ...value.filterQuery,
        extended: true,
      });
      onAdvancedQuery(!value.advancedQuery);
    },
    [onAdvancedQuery, setFilterQuery]
  );

  const onChangeView = useCallback(
    (event: RadioChangeEvent) => {
      setArticleView(event.target.value);
    },
    [setArticleView]
  );

  const getRole = useCallback(
    async (
      currentAccessToken: string,
      controller: AbortController
    ): Promise<void> => {
      await getSession(currentAccessToken, controller).then(
        (sessionData: any) => {
          setRole(sessionData ? sessionData.user.role.name : "");
        }
      );
    },
    []
  );

  useEffect(() => {
    let controller = new AbortController();
    if (accessToken !== null) {
      getRole(accessToken, controller);
    }
    return () => controller.abort();
  }, [accessToken, getRole]);

  return (
    <BasketContext.Consumer>
      {(value) => (
        <Row
          style={{
            // marginBottom: 10,
            position: "sticky",
            top: 0,
            backgroundColor: "#FFFFFF",
            // backgroundColor: "blue",
            zIndex: 2,
          }}
        >
          <Col flex="40px" style={{ paddingTop: 6 }}>
            <Button
              icon={<HomeOutlined />}
              onClick={cancelSearch}
              type="link"
            />
          </Col>

          {title !== undefined && (
            <Col flex="400px" style={{ paddingTop: 8 }}>
              <h3>{title}</h3>
            </Col>
          )}

          {title === undefined && (
            <Col flex="380px" style={{ paddingTop: 6 }}>
              <Search
                placeholder="Rechercher une pièce"
                onSearch={onSearchArticle}
                style={{ width: 380 }}
                enterButton
                defaultValue={value.query}
                addonBefore={
                  <Button
                    icon={
                      value.advancedQuery ? (
                        <MinusSquareOutlined />
                      ) : (
                        <PlusSquareOutlined />
                      )
                    }
                    style={{ height: 30, margin: 0, padding: 0 }}
                    type="link"
                    onClick={() => setAdvancedQuery(value)}
                  >
                    Recherche étendue
                  </Button>
                }
              />
            </Col>
          )}
          {showEtai && title === undefined && (
            <Col flex="120px" style={{ marginLeft: 10, paddingTop: 6 }}>
              <Button
                type="default"
                style={{ width: "100%", height: 32, margin: 0 }}
                onClick={onSearchEtai}
              >
                <img src="logo-etai.png" alt="ETAI" style={{ height: 25 }} />
              </Button>
            </Col>
          )}
          {showTecdoc && title === undefined && (
            <Col flex="140px" style={{ marginLeft: 10, paddingTop: 6 }}>
              <Button
                type="default"
                icon={<FontAwesomeIcon icon={faCar} />}
                style={{ width: "100%", height: 32, margin: 0 }}
              >
                <Link to="/tecdoc"> Par modèle de véhicule</Link>
              </Button>
            </Col>
          )}

          <Col flex="auto" style={{ paddingTop: 6, textAlign: "right" }}>
            {role !== "" && role !== "ROLE_GUEST" && (
              <Radio.Group value={value.articleView} onChange={onChangeView}>
                <Radio.Button value="table">
                  <TableOutlined />
                </Radio.Button>
                <Radio.Button value="list">
                  <BarsOutlined />
                </Radio.Button>
              </Radio.Group>
            )}
          </Col>
        </Row>
      )}
    </BasketContext.Consumer>
  );
};
export default withBasket(HeadbandSearch);

// class HeadbandSearch extends React.Component<
//   {
//     showEtai: boolean;
//     showTecdoc: boolean;
//     onSearchArticle: any;
//     onAdvancedQuery: any;
//     onSearchEtai: any;
//     cancelSearch: any;
//     onChangeQuery: any;
//     setArticleView: any;
//     setFilterQuery: any;
//     title: string | undefined;
//   },
//   { role: string }
// > {
//   private loginService: LoginService = new LoginService();

//   private accessToken = sessionStorage.getItem("accessToken");

//   constructor(props: {
//     showEtai: boolean;
//     showTecdoc: boolean;
//     onSearchArticle: any;
//     onAdvancedQuery: any;
//     onSearchEtai: any;
//     cancelSearch: any;
//     onChangeQuery: any;
//     setArticleView: any;
//     setFilterQuery: any;
//     title: string | undefined;
//   }) {
//     super(props);

//     this.state = {
//       role: "",
//     };
//   }

//   componentDidMount() {
//     if (this.accessToken !== null) {
//       this.loginService.session(this.accessToken).then((sessionData: any) => {
//         this.setState({
//           role: sessionData ? sessionData.user.role.name : "",
//         });
//       });
//     }
//   }

//   render() {
//     return (
//       <BasketContext.Consumer>
//         {(value) => (
//           <Row style={{ marginBottom: 10 }}>
//             <Col flex="40px" style={{ paddingTop: 6 }}>
//               <Button
//                 icon={<ArrowLeftOutlined />}
//                 onClick={this.props.cancelSearch}
//                 type="link"
//               />
//             </Col>

//             {this.props.title !== undefined && (
//               <Col flex="400px" style={{ paddingTop: 8 }}>
//                 <h3>{this.props.title}</h3>
//               </Col>
//             )}

//             {this.props.title === undefined && (
//               <Col flex="380px" style={{ paddingTop: 6 }}>
//                 <Search
//                   placeholder="Rechercher une pièce"
//                   onSearch={this.props.onSearchArticle}
//                   style={{ width: 380 }}
//                   enterButton
//                   defaultValue={value.query}
//                   addonBefore={
//                     <Button
//                       icon={
//                         value.advancedQuery ? (
//                           <MinusSquareOutlined />
//                         ) : (
//                           <PlusSquareOutlined />
//                         )
//                       }
//                       style={{ height: 30, margin: 0, padding: 0 }}
//                       type="link"
//                       onClick={() => this.setAdvancedQuery(value)}
//                     >
//                       Recherche étendue
//                     </Button>
//                   }
//                 />
//               </Col>
//             )}
//             {this.props.showEtai && this.props.title === undefined && (
//               <Col flex="120px" style={{ marginLeft: 10, paddingTop: 6 }}>
//                 <Button
//                   type="default"
//                   style={{ width: "100%", height: 32, margin: 0 }}
//                   onClick={this.props.onSearchEtai}
//                 >
//                   <img src="logo-etai.png" alt="ETAI" style={{ height: 25 }} />
//                 </Button>
//               </Col>
//             )}
//             {this.props.showTecdoc && this.props.title === undefined && (
//               <Col flex="140px" style={{ marginLeft: 10, paddingTop: 6 }}>
//                 <Button
//                   type="default"
//                   icon={<FontAwesomeIcon icon={faCar} />}
//                   style={{ width: "100%", height: 32, margin: 0 }}
//                 >
//                   <Link to="/tecdoc"> Par modèle de véhicule</Link>
//                 </Button>
//               </Col>
//             )}

//             <Col flex="auto" style={{ paddingTop: 6, textAlign: "right" }}>
//               {this.state.role !== "" && this.state.role !== "ROLE_GUEST" && (
//                 <Radio.Group
//                   value={value.articleView}
//                   onChange={this.onChangeView}
//                 >
//                   <Radio.Button value="table">
//                     <TableOutlined />
//                   </Radio.Button>
//                   <Radio.Button value="list">
//                     <BarsOutlined />
//                   </Radio.Button>
//                 </Radio.Group>
//               )}
//             </Col>
//           </Row>
//         )}
//       </BasketContext.Consumer>
//     );
//   }

//   private setAdvancedQuery = (value: any) => {
//     this.props.setFilterQuery({
//       ...value.filterQuery,
//       extended: true,
//     });
//     this.props.onAdvancedQuery(!value.advancedQuery);
//   };

//   private onChangeView = (event: RadioChangeEvent) => {
//     this.props.setArticleView(event.target.value);
//   };
// }

// export default withBasket(HeadbandSearch);
