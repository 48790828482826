import { useCallback, useContext, useEffect, useState } from "react";
import "../../App.css";
import { Row, Col, Input, Button, Card, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCarBattery } from "@fortawesome/free-solid-svg-icons";

import { BasketContext } from "../../Utils/BasketProvider";
import TecdocCardSearch from "../tecdoc/component/TecdocCardSearch";
import CatalogVehicleNodeCardSearch from "../catalog/component/CatalogVehicleNodeCardSearch";
import CatalogFamilyCardSearch from "../catalog/component/CatalogFamilyCardSearch";
import CatalogCharacteristicCardSearch from "../catalog/component/CatalogCharacteristicCardSearch";
import { findParameterValue } from "../../Utils/ParameterServiceFunctional";
import CatdataSearch from "./CatdataSearch";

type CardsSearchProps = {
  dataLoading: boolean;
  showTecdoc: boolean;
  showEtai: boolean;
  showCatalogF1: boolean;
  showCatdataSearch: boolean;
  onSearchArticle: (query: string) => void;
  // onAdvancedQuery: (value: boolean) => void;
  onSearchTecdoc: (query: string, queryType: string) => void;
  onSearchEtai: () => void;
};

const CardsSearch = (props: CardsSearchProps): JSX.Element => {
  const {
    dataLoading,
    showTecdoc,
    showEtai,
    showCatalogF1,
    showCatdataSearch,
    onSearchArticle,
    // onAdvancedQuery,
    onSearchTecdoc,
    onSearchEtai,
  } = props;

  const contextValues = useContext(BasketContext);

  const [CATALOG_SEARCH_MODE, setCATALOG_SEARCH_MODE] = useState<
    string | undefined
  >(undefined);
  const [colSpanLgState, setColSpanLgState] = useState<number>(1);
  const [colSpanMdState, setColSpanMdState] = useState<number>(1);

  const Search = Input.Search;

  const initColSpan = useCallback(() => {
    let numberOfCard = 1;
    if (showCatalogF1 && showCatdataSearch) {
      numberOfCard++;
    }
    if (showTecdoc && contextValues.customProfil !== undefined) {
      numberOfCard++;
    }
    if (
      showCatalogF1 &&
      CATALOG_SEARCH_MODE !== undefined &&
      (CATALOG_SEARCH_MODE === "VEHICLE_NODE" ||
        CATALOG_SEARCH_MODE === "VEHICLE_NODE_FAMILY")
    ) {
      numberOfCard++;
    }
    if (
      showCatalogF1 &&
      CATALOG_SEARCH_MODE !== undefined &&
      (CATALOG_SEARCH_MODE === "FAMILY" ||
        CATALOG_SEARCH_MODE === "VEHICLE_NODE_FAMILY")
    ) {
      numberOfCard++;
    }
    if (
      showCatalogF1 &&
      CATALOG_SEARCH_MODE !== undefined &&
      CATALOG_SEARCH_MODE === "CHARACTERISTIC"
    ) {
      numberOfCard++;
    }
    if (showEtai) {
      numberOfCard++;
    }

    let colSpan;

    if (numberOfCard > 4) {
      colSpan = 6;
    } else {
      colSpan = 24 / numberOfCard;
    }
    setColSpanLgState(colSpan);
    setColSpanMdState(12);

    console.log("numberOfCard : ", numberOfCard);
  }, [
    CATALOG_SEARCH_MODE,
    contextValues.customProfil,
    showCatalogF1,
    showCatdataSearch,
    showEtai,
    showTecdoc,
  ]);

  useEffect(() => {
    findParameterValue("CATALOG_SEARCH_MODE").then((value: string) => {
      setCATALOG_SEARCH_MODE(value);
      initColSpan();
    });
  }, [initColSpan]);

  return (
    <Spin size="large" spinning={dataLoading}>
      <BasketContext.Consumer>
        {(contextValues) => (
          <Row
            gutter={12}
            justify="center"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Col
              sm={colSpanMdState}
              lg={colSpanLgState}
              //  flex={"380px"} style={{ marginTop: 5 }}
            >
              <Card
                bordered={false}
                title={
                  <div>
                    <FontAwesomeIcon icon={faCarBattery} /> Recherche de pièces
                  </div>
                }
                size="small"
                bodyStyle={{ minHeight: 100, marginTop: 10 }}
                headStyle={{
                  fontWeight: "bold",
                  background: "#FFFFFF",
                  fontSize: "1.1em",
                  color: "#545454",
                }}
              >
                <Search
                  size="large"
                  placeholder="EAN, référence, désignation, fabricant..."
                  onSearch={onSearchArticle}
                  style={{ width: "100%" }}
                  enterButton
                  defaultValue={contextValues.query}
                  autoFocus={true}
                />
              </Card>
            </Col>
            {showCatalogF1 && showCatdataSearch && (
              <Col
                sm={colSpanMdState}
                lg={colSpanLgState}
                // flex={"380px"} style={{ marginTop: 5 }}
              >
                <CatdataSearch />
              </Col>
            )}

            {showTecdoc && contextValues.customProfil !== undefined && (
              <Col
                sm={colSpanMdState}
                lg={colSpanLgState}
                // flex={"380px"} style={{ marginTop: 5 }}
              >
                <TecdocCardSearch onSearchTecdoc={onSearchTecdoc} />
              </Col>
            )}

            {showCatalogF1 &&
              CATALOG_SEARCH_MODE !== undefined &&
              (CATALOG_SEARCH_MODE === "VEHICLE_NODE" ||
                CATALOG_SEARCH_MODE === "VEHICLE_NODE_FAMILY") && (
                <Col
                  sm={colSpanMdState}
                  lg={colSpanLgState}
                  // flex={"380px"} style={{ marginTop: 5 }}
                >
                  <CatalogVehicleNodeCardSearch />
                </Col>
              )}

            {showCatalogF1 &&
              CATALOG_SEARCH_MODE !== undefined &&
              (CATALOG_SEARCH_MODE === "FAMILY" ||
                CATALOG_SEARCH_MODE === "VEHICLE_NODE_FAMILY") && (
                <Col
                  sm={colSpanMdState}
                  lg={colSpanLgState}
                  // flex={"380px"} style={{ marginTop: 5 }}
                >
                  <CatalogFamilyCardSearch />
                </Col>
              )}

            {showCatalogF1 &&
              CATALOG_SEARCH_MODE !== undefined &&
              CATALOG_SEARCH_MODE === "CHARACTERISTIC" && (
                <Col
                  sm={colSpanMdState}
                  lg={colSpanLgState}
                  // flex={"380px"} style={{ marginTop: 5 }}
                >
                  <CatalogCharacteristicCardSearch />
                </Col>
              )}

            {showEtai && (
              <Col
                sm={colSpanMdState}
                lg={colSpanLgState}
                // flex={"380px"} style={{ marginTop: 5 }}
              >
                <Card
                  bordered={false}
                  title={
                    <div>
                      <FontAwesomeIcon icon={faBook} /> Recherche par catalogues
                    </div>
                  }
                  size="small"
                  bodyStyle={{ minHeight: 100, marginTop: 5 }}
                  headStyle={{
                    fontWeight: "bold",
                    background: "#FFFFFF",
                    fontSize: "1.1em",
                    color: "#545454",
                  }}
                >
                  <Button
                    type="link"
                    style={{ margin: 0, padding: 0, height: 55 }}
                    onClick={onSearchEtai}
                  >
                    <img
                      src="logo-etai.png"
                      alt="ETAI"
                      style={{ height: 55 }}
                    />
                  </Button>
                </Card>
              </Col>
            )}
          </Row>
        )}
      </BasketContext.Consumer>
    </Spin>
  );
};
export default CardsSearch;

// class CardsSearch extends React.Component<
//   {
//     dataLoading: boolean;
//     showTecdoc: boolean;
//     showEtai: boolean;
//     showCatalogF1: boolean;
//     showCatdataSearch: boolean;
//     onSearchArticle: any;
//     onAdvancedQuery: any;
//     onSearchTecdoc: any;
//     onSearchEtai: any;
//   },
//   {
//     CATALOG_SEARCH_MODE: string | undefined;
//   }
// > {
//   private parameterService: ParameterService = new ParameterService();

//   constructor(props: {
//     dataLoading: boolean;
//     showTecdoc: boolean;
//     showEtai: boolean;
//     showCatalogF1: boolean;
//     showCatdataSearch: boolean;
//     onSearchArticle: any;
//     onAdvancedQuery: any;
//     onSearchTecdoc: any;
//     onSearchEtai: any;
//   }) {
//     super(props);

//     this.state = {
//       CATALOG_SEARCH_MODE: undefined,
//     };
//   }

//   private catalogService: CatalogService = new CatalogService();

//   componentDidMount() {
//     this.parameterService
//       .findValue("CATALOG_SEARCH_MODE")
//       .then((value: string) => {
//         this.setState({ CATALOG_SEARCH_MODE: value });
//       });
//   }

//   render() {
//     return (
//       <Spin size="large" spinning={this.props.dataLoading}>
//         <BasketContext.Consumer>
//           {(contextValues) => (
//             <Row
//               gutter={12}
//               justify="center"
//               style={{ marginTop: 15, marginBottom: 15 }}
//             >
//               <Col flex={"380px"} style={{ marginTop: 5 }}>
//                 <Card
//                   bordered={false}
//                   title={
//                     <div>
//                       <FontAwesomeIcon icon={faCarBattery} /> Recherche de
//                       pièces
//                     </div>
//                   }
//                   size="small"
//                   bodyStyle={{ minHeight: 100, marginTop: 10 }}
//                   headStyle={{
//                     fontWeight: "bold",
//                     background: "#FFFFFF",
//                     fontSize: "1.1em",
//                     color: "#545454",
//                   }}
//                 >
//                   <Search
//                     size="large"
//                     placeholder="EAN, référence, désignation, fabricant..."
//                     onSearch={this.props.onSearchArticle}
//                     style={{ width: "100%" }}
//                     enterButton
//                     defaultValue={contextValues.query}
//                     autoFocus={true}
//                   />
//                 </Card>
//               </Col>

//               {/* {this.props.showTecdoc &&
//                 contextValues.customProfil !== undefined && (
//                   <Col flex={"380px"} style={{ marginTop: 5 }}>
//                     <TecdocCardSearch
//                       onSearchTecdoc={this.props.onSearchTecdoc}
//                     />
//                   </Col>
//                 )} */}

//               {this.props.showCatalogF1 && this.props.showCatdataSearch && (
//                 <Col flex={"380px"} style={{ marginTop: 5 }}>
//                   <Card
//                     bordered={false}
//                     title={
//                       <div>
//                         <FontAwesomeIcon icon={faCar} /> Par immatriculation
//                       </div>
//                     }
//                     size="small"
//                     bodyStyle={{ minHeight: 100, marginTop: 10 }}
//                     headStyle={{
//                       fontWeight: "bold",
//                       background: "#FFFFFF",
//                       fontSize: "1.1em",
//                       color: "#545454",
//                     }}
//                   >
//                     <Search
//                       size="large"
//                       placeholder="Immatriculation : AA-123-BB"
//                       onSearch={(value: any) =>
//                         this.onSearchPlateNumber(value, "plateNumber")
//                       }
//                       style={{ width: "100%" }}
//                       enterButton
//                       defaultValue={
//                         contextValues.tecdocSearch.query !== undefined
//                           ? contextValues.tecdocSearch.query.plateNumber
//                           : ""
//                       }
//                     />
//                     <div
//                       id="scrollableDiv"
//                       style={{
//                         height: 200,
//                         overflow: "auto",
//                         padding: "0 16px",
//                         border: "1px solid rgba(140, 140, 140, 0.35)",
//                       }}
//                     >
//                       <List
//                         size="small"
//                         dataSource={data}
//                         renderItem={(item: string) => (
//                           <List.Item>{item}</List.Item>
//                         )}
//                       />
//                     </div>
//                     {/* </div> */}
//                   </Card>
//                 </Col>
//               )}

//               {this.props.showTecdoc &&
//                 contextValues.customProfil !== undefined && (
//                   <Col flex={"380px"} style={{ marginTop: 5 }}>
//                     <TecdocCardSearch
//                       onSearchTecdoc={this.props.onSearchTecdoc}
//                     />
//                   </Col>
//                 )}

//               {this.props.showCatalogF1 &&
//                 this.state.CATALOG_SEARCH_MODE !== undefined &&
//                 (this.state.CATALOG_SEARCH_MODE === "VEHICLE_NODE" ||
//                   this.state.CATALOG_SEARCH_MODE === "VEHICLE_NODE_FAMILY") && (
//                   <Col flex={"380px"} style={{ marginTop: 5 }}>
//                     <CatalogVehicleNodeCardSearch />
//                   </Col>
//                 )}

//               {this.props.showCatalogF1 &&
//                 this.state.CATALOG_SEARCH_MODE !== undefined &&
//                 (this.state.CATALOG_SEARCH_MODE === "FAMILY" ||
//                   this.state.CATALOG_SEARCH_MODE === "VEHICLE_NODE_FAMILY") && (
//                   <Col flex={"380px"} style={{ marginTop: 5 }}>
//                     <CatalogFamilyCardSearch />
//                   </Col>
//                 )}

//               {this.props.showCatalogF1 &&
//                 this.state.CATALOG_SEARCH_MODE !== undefined &&
//                 this.state.CATALOG_SEARCH_MODE === "CHARACTERISTIC" && (
//                   <Col flex={"380px"} style={{ marginTop: 5 }}>
//                     <CatalogCharacteristicCardSearch />
//                   </Col>
//                 )}

//               {this.props.showEtai && (
//                 <Col flex={"380px"} style={{ marginTop: 5 }}>
//                   <Card
//                     bordered={false}
//                     title={
//                       <div>
//                         <FontAwesomeIcon icon={faBook} /> Recherche par
//                         catalogues
//                       </div>
//                     }
//                     size="small"
//                     bodyStyle={{ minHeight: 100, marginTop: 5 }}
//                     headStyle={{
//                       fontWeight: "bold",
//                       background: "#FFFFFF",
//                       fontSize: "1.1em",
//                       color: "#545454",
//                     }}
//                   >
//                     <Button
//                       type="link"
//                       style={{ margin: 0, padding: 0, height: 55 }}
//                       onClick={this.props.onSearchEtai}
//                     >
//                       <img
//                         src="logo-etai.png"
//                         alt="ETAI"
//                         style={{ height: 55 }}
//                       />
//                     </Button>
//                   </Card>
//                 </Col>
//               )}
//             </Row>
//           )}
//         </BasketContext.Consumer>
//       </Spin>
//     );
//   }
//   private onSearchPlateNumber = (query: any, queryType: string) => {
//     if (query !== undefined && query !== "") {
//       console.log("query : ", query);
//       console.log("queryType : ", queryType);
//       this.catalogService
//         .searchVehicleByPlateNumberCatdata(query.toUpperCase(), undefined)
//         .then((vehiculeSearch: IVehicleSearch) => {
//           console.log("vehiculeSearch : ", vehiculeSearch);
//         });
//     }
//   };
// }

// export default CardsSearch;
