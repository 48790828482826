import { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch as SwitchRouter,
} from "react-router-dom";
import "./App.css";

import { Layout, ConfigProvider, Empty, Spin, Row, Col } from "antd";
import frFR from "antd/es/locale/fr_FR";

import Home from "./containers/home/Home";
import Login from "./containers/login/Login";
import MyAccount from "./containers/myaccount/MyAccount";
import Mail from "./containers/mail/Mail";
import Bookmark from "./containers/bookmark/Bookmark";
import DocumentHistory from "./containers/document/DocumentHistory";
import ShoppingCart from "./containers/shoppingcart/ShoppingCart";
import Wishlist from "./containers/Wishlist";
import BasketProvider, { withBasket } from "./Utils/BasketProvider";
import Footer from "./containers/Footer";
import SalesOrderHistory from "./containers/shoppingcart/SalesOrderHistory";
import ImportSalesOrder from "./containers/shoppingcart/ImportSalesOrder";
import Return from "./containers/document/Return";
import ReturnRequest from "./containers/document/ReturnRequest";
import TecdocSearch from "./containers/tecdoc/TecdocSearch";
import NavigationProvider, {
  NavigationContext,
  GetMenuKey,
} from "./Utils/NavigationProvider";
import CatalogSearch from "./containers/catalog/CatalogSearch";
import PageRequest from "./containers/PageRequest";
import FlybyHeader from "./containers/Header";
import MarketParameterProvider, {
  MarketParameterContext,
} from "./Utils/MarketParameterContext";
import Title from "./Utils/Title";
import HeaderLogo from "./Utils/HeaderLogo";
import DocumentHistoryCustom from "./containers/document/DocumentHistoryCustom";
import Library from "./containers/library/Library";
import RecoveryIdentifier from "./containers/login/RecoveryIdentifier";
import {
  getSession,
  setUserParamsFunctional,
} from "./containers/login/LoginServiceFunctional";
import CustomizedMenu from "./containers/menu/CustomizedMenu";

const { Header, Content, Sider } = Layout;

const customizeRenderEmpty = () => <Empty description={"Pas de résultat"} />;

const App: React.FunctionComponent = () => {
  const [showWishlistState, setShowWishlistState] = useState<boolean>(true);
  const [isAuthenticatedState, setIsAuthenticatedState] = useState<number>(0);
  const [pageState, setPageState] = useState<string | null>("");
  const [roleState, setRoleState] = useState<string>("");

  const onLogin = useCallback((isAuthenticated: boolean) => {
    let accessToken = sessionStorage.getItem("accessToken");

    if (accessToken !== null) {
      getSession(accessToken).then((sessionData: any) => {
        setIsAuthenticatedState(2);
        setRoleState(sessionData ? sessionData.user.role.name : "");
      });
    }
  }, []);

  const localAuthentication = useCallback(() => {
    let accessToken = sessionStorage.getItem("accessToken");

    if (accessToken !== null) {
      getSession(accessToken).then((sessionData: any) => {
        let isAuthenticated = 1;

        if (sessionData !== undefined) {
          isAuthenticated = 2;
        } else {
          sessionStorage.clear();
        }

        setTimeout(() => {
          setIsAuthenticatedState(isAuthenticated);
          setRoleState(sessionData ? sessionData.user.role.name : "");
        }, 500);
      });
    } else {
      setIsAuthenticatedState(1);
      setRoleState("");
    }
  }, []);

  const externalAuthentication = useCallback((token: string, page: string) => {
    sessionStorage.setItem("accessToken", token);

    getSession(token).then((sessionData: any) => {
      let isAuthenticated = 4;

      if (sessionData !== undefined && token !== null) {
        setUserParamsFunctional(token).then((success: any) => {
          if (success) {
            isAuthenticated = 3;
          }
        });
      } else {
        isAuthenticated = 4;
        sessionStorage.clear();
      }

      setTimeout(() => {
        setIsAuthenticatedState(isAuthenticated);
        setPageState(page);
        setRoleState(sessionData ? sessionData.user.role.name : "");
      }, 500);
    });
  }, []);

  const checkAuthentification = useCallback(() => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let token: string | null = urlParams.get("token");
    let page: string | null = urlParams.get("page");

    if (token !== null && page !== null) {
      externalAuthentication(token, page);
    } else {
      localAuthentication();
    }
  }, [externalAuthentication, localAuthentication]);

  useEffect(() => {
    checkAuthentification();
  }, [checkAuthentification]);

  const selectedMenuKey = GetMenuKey();

  if (selectedMenuKey === "/recovery-identifier") {
    return (
      <MarketParameterProvider>
        <Title />
        <RecoveryIdentifier />
      </MarketParameterProvider>
    );
  }

  if (isAuthenticatedState === 1) {
    return (
      <MarketParameterProvider>
        <Login onLogin={onLogin} />
      </MarketParameterProvider>
    );
  }

  if (isAuthenticatedState === 2) {
    return (
      <Router>
        <MarketParameterProvider>
          <NavigationProvider>
            <ConfigProvider renderEmpty={customizeRenderEmpty} locale={frFR}>
              <Title />
              <BasketProvider>
                <Layout style={{ height: "100vh" }}>
                  <Layout style={{ overflowY: "hidden", background: "#fff" }}>
                    <FlybyHeader />

                    <MarketParameterContext.Consumer>
                      {(appParms) => (
                        <Header
                          style={{
                            background:
                              appParms.MENU_THEME === "dark"
                                ? "#001529"
                                : "#fff",
                            // background: "#001529",
                            paddingLeft: 0,
                            paddingRight: 10,
                            // paddingTop: 5,
                          }}
                        >
                          <NavigationContext.Consumer>
                            {(appMenu) => (
                              <Row>
                                <Col
                                  span={3}
                                  style={{
                                    display: "block",
                                    textAlign: "center",
                                    background: "#FFFFFF",
                                    height: "64px",
                                  }}
                                >
                                  <HeaderLogo />
                                </Col>
                                <Col span={21}>
                                  <CustomizedMenu
                                    roleState={roleState}
                                    showWishlistState={showWishlistState}
                                    setShowWishlistState={setShowWishlistState}
                                    setIsAuthenticatedState={
                                      setIsAuthenticatedState
                                    }
                                  />
                                </Col>
                              </Row>
                            )}
                          </NavigationContext.Consumer>
                        </Header>
                      )}
                    </MarketParameterContext.Consumer>

                    <Layout>
                      <Content
                        id="mainContent"
                        // ref={"mainContent"}
                        style={{
                          overflowY: "auto",
                          background: "#fff",
                          paddingTop: 20,
                        }}
                      >
                        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                          <SwitchRouter>
                            {roleState === "ROLE_GUEST" &&
                              selectedMenuKey !== "/home" &&
                              selectedMenuKey !== "/mail" && (
                                <Redirect push to="/" />
                              )}
                            <Route exact path="/" component={Home} />
                            <Route path="/index.html" component={Home} />
                            <Route path="/home" component={Home} />
                            <Route path="/my-acccount" component={MyAccount} />
                            <Route path="/mail" component={Mail} />
                            <Route path="/bookmark" component={Bookmark} />
                            <Route
                              path="/sales-order-history"
                              component={SalesOrderHistory}
                            />
                            <Route
                              path="/shopping-cart"
                              component={ShoppingCart}
                            />
                            <Route
                              path="/import-sales-order"
                              component={ImportSalesOrder}
                            />
                            <Route
                              path="/documents-history"
                              component={() => <DocumentHistory />}
                            />
                            <Route
                              path="/custom-documents-history"
                              component={() => <DocumentHistoryCustom />}
                            />
                            <Route
                              path="/returns"
                              component={() => (
                                <Return externalRequest={false} />
                              )}
                            />
                            <Route
                              path="/return-request"
                              component={() => (
                                <ReturnRequest externalRequest={false} />
                              )}
                            />
                            <Route path="/library" component={Library} />
                            <Route path="/tecdoc" component={TecdocSearch} />
                            <Route path="/catalog" component={CatalogSearch} />
                          </SwitchRouter>
                        </div>
                        <Footer isAuthenticated={isAuthenticatedState} />
                      </Content>

                      <Sider
                        collapsed={showWishlistState}
                        collapsedWidth={0}
                        style={{ background: "#fff" }}
                        width={250}
                      >
                        <Wishlist />
                      </Sider>
                    </Layout>
                  </Layout>
                </Layout>
              </BasketProvider>
            </ConfigProvider>
          </NavigationProvider>
        </MarketParameterProvider>
      </Router>
    );
  }

  if (isAuthenticatedState === 3) {
    return (
      <Router>
        <ConfigProvider renderEmpty={customizeRenderEmpty} locale={frFR}>
          <MarketParameterProvider>
            <Title />
            <BasketProvider>
              <SwitchRouter>
                <Route
                  path="/"
                  component={() => <PageRequest page={pageState} />}
                />
              </SwitchRouter>
            </BasketProvider>
          </MarketParameterProvider>
        </ConfigProvider>
      </Router>
    );
  }

  if (isAuthenticatedState === 4) {
    return (
      <MarketParameterProvider>
        <Title />
        <div style={{ textAlign: "center", paddingTop: 40 }}>
          <Spin tip="Impossible de vous identifier..." size="large" />
        </div>
      </MarketParameterProvider>
    );
  }

  return (
    <MarketParameterProvider>
      <Title />
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        <Spin tip="Chargement..." size="large" />
      </div>
    </MarketParameterProvider>
  );
};

export default withBasket(App);

// const { Header, Content, Sider } = Layout;

// const customizeRenderEmpty = () => <Empty description={"Pas de résultat"} />;

// class App extends React.Component<
//   {
//     clearTecdocSearch: any;
//   },
//   {
//     showWishlist: boolean;
//     isAuthenticated: number;
//     page: string | null;
//     role: string;
//     data: ISession | undefined;
//     displayNameAndCompanyName: string | undefined;
//   }
// > {
//   private loginService: LoginService = new LoginService();

//   constructor(props: { clearTecdocSearch: any }) {
//     super(props);

//     this.state = {
//       showWishlist: false,
//       isAuthenticated: 0,
//       page: "",
//       role: "",
//       data: undefined,
//       displayNameAndCompanyName: undefined,
//     };
//   }

//   componentDidMount() {
//     let queryString = window.location.search;
//     let urlParams = new URLSearchParams(queryString);
//     let token: string | null = urlParams.get("token");
//     let page: string | null = urlParams.get("page");

//     if (token !== null && page !== null) {
//       this.externalAuthentication(token, page);
//     } else {
//       this.localAuthentication();
//     }
//   }

//   render() {
//     const selectedMenuKey = GetMenuKey();

//     if (selectedMenuKey === "/recovery-identifier") {
//       return (
//         <MarketParameterProvider>
//           <Title />
//           <RecoveryIdentifier />
//         </MarketParameterProvider>
//       );
//     }

//     if (this.state.isAuthenticated === 1) {
//       return (
//         <MarketParameterProvider>
//           <Login onLogin={this.onLogin} />
//         </MarketParameterProvider>
//       );
//     }

//     if (this.state.isAuthenticated === 2) {
//       return (
//         <Router>
//           <MarketParameterProvider>
//             <NavigationProvider>
//               <ConfigProvider renderEmpty={customizeRenderEmpty} locale={frFR}>
//                 <Title />
//                 <BasketProvider>
//                   <Layout style={{ height: "100vh" }}>
//                     <Layout style={{ overflowY: "hidden", background: "#fff" }}>
//                       <FlybyHeader />

//                       <Header
//                         style={{
//                           // background: "#fff",
//                           paddingLeft: 0,
//                           paddingRight: 10,
//                           // paddingTop: 5,
//                         }}
//                       >
//                         <MarketParameterContext.Consumer>
//                           {(appParms) => (
//                             <NavigationContext.Consumer>
//                               {(appMenu) => (
//                                 <>
//                                   <Menu
//                                     onClick={(selectedMenu) =>
//                                       appMenu.updateMenuKey(selectedMenu.key)
//                                     }
//                                     selectedKeys={[appMenu.selectedMenuKey]}
//                                     mode="horizontal"
//                                     theme="dark"
//                                     style={{
//                                       justifyContent: "space-between",
//                                       height: "100%",
//                                       width: "auto",
//                                       maxHeight: "100%",
//                                       maxWidth: "auto",
//                                     }}
//                                   >
//                                     <HeaderLogo />
//                                     <Menu.Item
//                                       key="/home"
//                                       icon={<HomeOutlined />}
//                                     >
//                                       <Link to="/home">Accueil</Link>
//                                     </Menu.Item>

//                                     <Menu.Item
//                                       key="/mail"
//                                       icon={<MailOutlined />}
//                                     >
//                                       <Link to="/mail">Nous contacter</Link>
//                                     </Menu.Item>

//                                     <Menu.SubMenu
//                                       key="/acccount"
//                                       icon={<IdcardOutlined />}
//                                       title="Mon compte"
//                                       disabled={
//                                         this.state.role === "ROLE_GUEST"
//                                       }
//                                       theme="light"
//                                     >
//                                       <Menu.Item
//                                         key="/documents-history"
//                                         icon={<ReadOutlined />}
//                                       >
//                                         <Link to="/documents-history">
//                                           Mes documents
//                                         </Link>
//                                       </Menu.Item>

//                                       {appParms.CUSTOM_DOCUMENTS_HISTORY !==
//                                         null &&
//                                         appParms.CUSTOM_DOCUMENTS_HISTORY !==
//                                           "" && (
//                                           <Menu.Item
//                                             key="/custom-documents-history"
//                                             icon={<ContainerOutlined />}
//                                           >
//                                             <Link to="/custom-documents-history">
//                                               {
//                                                 appParms.CUSTOM_DOCUMENTS_HISTORY
//                                               }
//                                             </Link>
//                                           </Menu.Item>
//                                         )}
//                                     </Menu.SubMenu>

//                                     <Menu.Item
//                                       key="/returns"
//                                       icon={<RollbackOutlined />}
//                                       disabled={
//                                         this.state.role === "ROLE_GUEST"
//                                       }
//                                     >
//                                       <Link to="/returns">
//                                         Demandes de retour
//                                       </Link>
//                                     </Menu.Item>

//                                     {appParms.DOCUMENT && (
//                                       <Menu.Item
//                                         key="/library"
//                                         icon={<HddOutlined />}
//                                         disabled={
//                                           this.state.role === "ROLE_GUEST"
//                                         }
//                                       >
//                                         <Link to="/library">Documentation</Link>
//                                       </Menu.Item>
//                                     )}

//                                     <Menu.Item
//                                       key="/bookmark"
//                                       icon={<StarOutlined />}
//                                       hidden={appParms.DISABLE_MARKET}
//                                       disabled={
//                                         this.state.role === "ROLE_GUEST"
//                                       }
//                                     >
//                                       <Link to="/bookmark">Mes favoris</Link>
//                                     </Menu.Item>

//                                     {!appParms.DISABLE_MARKET && (
//                                       <SubMenu
//                                         key="/sales-order"
//                                         icon={<FileDoneOutlined />}
//                                         title={
//                                           <span className="submenu-title-wrapper">
//                                             Mes commandes
//                                           </span>
//                                         }
//                                         disabled={
//                                           this.state.role === "ROLE_GUEST"
//                                         }
//                                       >
//                                         <Menu.Item
//                                           key="/sales-order-history"
//                                           icon={<HistoryOutlined />}
//                                         >
//                                           <Link to="/sales-order-history">
//                                             Historique des commandes
//                                           </Link>
//                                         </Menu.Item>
//                                         {appParms.ENABLE_IMPORT_ORDER && (
//                                           <Menu.Item
//                                             key="/import-sales-order"
//                                             icon={<UploadOutlined />}
//                                             disabled={
//                                               this.state.role === "ROLE_GUEST"
//                                             }
//                                           >
//                                             <Link to="/import-sales-order">
//                                               Importer une commande
//                                             </Link>
//                                           </Menu.Item>
//                                         )}
//                                       </SubMenu>
//                                     )}

//                                     <Menu.Item
//                                       key="/shopping-cart"
//                                       hidden={appParms.DISABLE_MARKET}
//                                       disabled={
//                                         this.state.role === "ROLE_GUEST"
//                                       }
//                                       icon={
//                                         <ShoppingCartOutlined
//                                           style={{
//                                             fontSize: 30,
//                                             position: "absolute",
//                                             top: "50%",
//                                             transform: "translateY(-50%)",
//                                             textAlign: "center",
//                                             padding: 0,
//                                           }}
//                                         />
//                                       }
//                                     >
//                                       <BasketContext.Consumer>
//                                         {(value) => (
//                                           <Link to="/shopping-cart">
//                                             <Badge
//                                               count={
//                                                 value.wishlistTotal
//                                                   .totalQuantity
//                                               }
//                                               overflowCount={999}
//                                               offset={[0, -5]}
//                                               showZero={false}
//                                             >
//                                               <div
//                                                 style={{ visibility: "hidden" }}
//                                               >
//                                                 ----
//                                               </div>
//                                             </Badge>
//                                           </Link>
//                                         )}
//                                       </BasketContext.Consumer>
//                                     </Menu.Item>

//                                     <SubMenu
//                                       key="/sales-user-account"
//                                       theme="dark"
//                                       title={
//                                         <UserOutlined
//                                           style={{
//                                             fontSize: 35,
//                                             verticalAlign: "middle",
//                                           }}
//                                         />
//                                       }
//                                     >
//                                       {this.state.displayNameAndCompanyName ===
//                                         "display" && (
//                                         <Menu.Item>
//                                           <h3>
//                                             {this.state.data?.user.firstName}{" "}
//                                             {this.state.data?.user.lastName.toUpperCase()}
//                                           </h3>
//                                         </Menu.Item>
//                                       )}

//                                       {this.state.displayNameAndCompanyName ===
//                                         "display" && (
//                                         <Menu.Item>
//                                           <h3>
//                                             {this.state.data?.customers[0].name.toUpperCase()}
//                                           </h3>
//                                         </Menu.Item>
//                                       )}

//                                       <Menu.Item>
//                                         <HeaderPhone />
//                                       </Menu.Item>

//                                       <Menu.Item
//                                         key="/my-acccount"
//                                         icon={<UserOutlined />}
//                                       >
//                                         <Link to="/my-acccount">
//                                           Modifier mes informations
//                                         </Link>
//                                       </Menu.Item>

//                                       <Menu.Item
//                                         key="/basket"
//                                         // style={{ float: "right" }}
//                                         // disabled
//                                         hidden={appParms.DISABLE_MARKET}
//                                         icon={<ShoppingCartOutlined />}
//                                       >
//                                         <Link
//                                           to="/basket"
//                                           onChange={this.onShowBasket}
//                                         >
//                                           Afficher le panier
//                                         </Link>
//                                       </Menu.Item>

//                                       {/* <Tooltip title="Voir mon panier">
//                                         <BasketSwitch
//                                           onChange={this.onShowBasket}
//                                           checked={this.state.showWishlist}
//                                           role={this.state.role}
//                                         />
//                                       </Tooltip> */}

//                                       <Menu.Item
//                                         key="/logout"
//                                         icon={<LogoutOutlined />}
//                                         onClick={this.onLogout}
//                                       >
//                                         <Link to="/logout">Déconnexion</Link>
//                                       </Menu.Item>
//                                     </SubMenu>
//                                   </Menu>
//                                   <HeaderPhone />
//                                 </>
//                               )}
//                             </NavigationContext.Consumer>
//                           )}
//                         </MarketParameterContext.Consumer>
//                       </Header>

//                       <Layout>
//                         <Content
//                           id="mainContent"
//                           // ref={"mainContent"}
//                           style={{
//                             overflowY: "auto",
//                             background: "#fff",
//                             paddingTop: 20,
//                           }}
//                         >
//                           <div style={{ paddingRight: 20, paddingLeft: 20 }}>
//                             <SwitchRouter>
//                               {this.state.role === "ROLE_GUEST" &&
//                                 selectedMenuKey !== "/home" &&
//                                 selectedMenuKey !== "/mail" && (
//                                   <Redirect push to="/" />
//                                 )}

//                               <Route exact path="/" component={Home} />
//                               <Route path="/index.html" component={Home} />
//                               <Route path="/home" component={Home} />
//                               <Route
//                                 path="/my-acccount"
//                                 component={MyAccount}
//                               />
//                               <Route path="/mail" component={Mail} />
//                               <Route path="/bookmark" component={Bookmark} />
//                               <Route
//                                 path="/sales-order-history"
//                                 component={SalesOrderHistory}
//                               />
//                               <Route
//                                 path="/shopping-cart"
//                                 component={ShoppingCart}
//                               />
//                               <Route
//                                 path="/import-sales-order"
//                                 component={ImportSalesOrder}
//                               />
//                               <Route
//                                 path="/documents-history"
//                                 component={() => <DocumentHistory />}
//                               />
//                               <Route
//                                 path="/custom-documents-history"
//                                 component={() => <DocumentHistoryCustom />}
//                               />
//                               <Route
//                                 path="/returns"
//                                 component={() => (
//                                   <Return externalRequest={false} />
//                                 )}
//                               />
//                               <Route
//                                 path="/return-request"
//                                 component={() => (
//                                   <ReturnRequest externalRequest={false} />
//                                 )}
//                               />
//                               <Route path="/library" component={Library} />
//                               <Route path="/tecdoc" component={TecdocSearch} />
//                               <Route
//                                 path="/catalog"
//                                 component={CatalogSearch}
//                               />
//                             </SwitchRouter>
//                           </div>
//                           <Footer
//                             isAuthenticated={this.state.isAuthenticated}
//                           />
//                         </Content>

//                         <Sider
//                           collapsed={this.state.showWishlist}
//                           collapsedWidth={0}
//                           style={{ background: "#fff" }}
//                           width={250}
//                         >
//                           <Wishlist />
//                         </Sider>
//                       </Layout>
//                     </Layout>
//                   </Layout>
//                 </BasketProvider>
//               </ConfigProvider>
//             </NavigationProvider>
//           </MarketParameterProvider>
//         </Router>
//       );
//     }

//     if (this.state.isAuthenticated === 3) {
//       return (
//         <Router>
//           <ConfigProvider renderEmpty={customizeRenderEmpty} locale={frFR}>
//             <MarketParameterProvider>
//               <Title />
//               <BasketProvider>
//                 <SwitchRouter>
//                   <Route
//                     path="/"
//                     component={() => <PageRequest page={this.state.page} />}
//                   />
//                 </SwitchRouter>
//               </BasketProvider>
//             </MarketParameterProvider>
//           </ConfigProvider>
//         </Router>
//       );
//     }

//     if (this.state.isAuthenticated === 4) {
//       return (
//         <MarketParameterProvider>
//           <Title />
//           <div style={{ textAlign: "center", paddingTop: 40 }}>
//             <Spin tip="Impossible de vous identifier..." size="large" />
//           </div>
//         </MarketParameterProvider>
//       );
//     }

//     return (
//       <MarketParameterProvider>
//         <Title />
//         <div style={{ textAlign: "center", paddingTop: 40 }}>
//           <Spin tip="Chargement..." size="large" />
//         </div>
//       </MarketParameterProvider>
//     );
//   }

//   private onLogin = (isAuthenticated: boolean) => {
//     let accessToken = sessionStorage.getItem("accessToken");
//     let displayNameAndCompanyName = sessionStorage.getItem(
//       "displayNameAndCompanyName"
//     );

//     if (accessToken !== null) {
//       this.loginService.session(accessToken).then((sessionData: any) => {
//         this.setState({
//           isAuthenticated: 2,
//           role: sessionData ? sessionData.user.role.name : "",
//           data: sessionData ? sessionData : undefined,
//         });
//       });
//     }
//     if (displayNameAndCompanyName !== null) {
//       this.setState({
//         displayNameAndCompanyName: displayNameAndCompanyName.toLowerCase(),
//       });
//       if (displayNameAndCompanyName === "DISPLAY") {
//       }
//     }
//   };

//   private onShowBasket = () => {
//     this.setState({ showWishlist: false });
//   };

//   private onLogout = () => {
//     sessionStorage.clear();
//     this.setState({
//       isAuthenticated: 1,
//     });
//   };

//   private localAuthentication = () => {
//     let accessToken = sessionStorage.getItem("accessToken");

//     if (accessToken !== null) {
//       this.loginService.session(accessToken).then((sessionData: any) => {
//         let isAuthenticated = 1;

//         if (sessionData !== undefined) {
//           isAuthenticated = 2;
//         } else {
//           sessionStorage.clear();
//         }

//         setTimeout(() => {
//           this.setState({
//             isAuthenticated: isAuthenticated,
//             role: sessionData ? sessionData.user.role.name : "",
//           });
//         }, 500);
//       });
//     } else {
//       this.setState({
//         isAuthenticated: 1,
//         role: "",
//       });
//     }
//   };

//   private externalAuthentication = (token: string, page: string) => {
//     sessionStorage.setItem("accessToken", token);

//     this.loginService.session(token).then((sessionData: any) => {
//       let isAuthenticated = 4;

//       if (sessionData !== undefined && token !== null) {
//         this.loginService.setUserParams(token).then((success: any) => {
//           if (success) {
//             isAuthenticated = 3;
//           }
//         });
//       } else {
//         isAuthenticated = 4;
//         sessionStorage.clear();
//       }

//       setTimeout(() => {
//         this.setState({
//           isAuthenticated: isAuthenticated,
//           page: page,
//           role: sessionData ? sessionData.user.role.name : "",
//         });
//       }, 500);
//     });
//   };
// }

// export default withBasket(App);
