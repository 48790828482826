import React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Radio, Typography, Row, Col } from "antd";
import { faCreditCard, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IStep from "./IStep";
import ISalesOrder from "./ISalesOrder";
import { PaymentState, PaymentMethod } from "../../Utils/PaymentUtils";
import FlybyUtils from "../../Utils/FlybyUtils";
import IOrderPlatforms from "./IOrderPlatforms";
import ParameterService from "../../Utils/ParameterService";

const { Text } = Typography;

class Step5 extends React.Component<
  {
    stepConf: IStep | undefined;
    values: any;
    onNextStep: any;
    refreshWishlist: any;
    refreshWishlistTotal: any;
    clearSalesOrder: any;
    updateSalesOrder: any;
  },
  {
    dataLoading: boolean;
    selectPaymentMethod: string | undefined;
    paymentOrderCustomer: string;
    paymentOrderPlatform: boolean;
    platformList: any;
    customerPlatformListId: any;
    multiPlatform: boolean;
  }
> {
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private parameterService: ParameterService = new ParameterService();
  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  private paymentIframe?: HTMLIFrameElement;

  constructor(props: {
    stepConf: IStep | undefined;
    values: any;
    onNextStep: any;
    refreshWishlist: any;
    refreshWishlistTotal: any;
    clearSalesOrder: any;
    updateSalesOrder: any;
    platformList: any;
    customerPlatformListId: any;
  }) {
    super(props);

    this.state = {
      dataLoading: false,
      selectPaymentMethod: undefined,
      paymentOrderCustomer: "disabled",
      paymentOrderPlatform: false,
      platformList: [],
      customerPlatformListId: [],
      multiPlatform: false,
    };
  }

  async loadIframe(customerId: number, wishlistIds: number[]) {
    if (!this.paymentIframe) {
      return;
    }

    const headers = new Headers();
    let deliveryId = this.props.values.salesOrder.deliveryId;
    let billingId = this.props.values.salesOrder.billingId;
    let step = this.props.stepConf?.key;
    let redirectUrl = `${window.location.origin}/shopping-cart?step=${step}&deliveryId=${deliveryId}&billingId=${billingId}`;

    headers.set("Authorization", "Bearer " + this.accessToken);
    headers.set("Accept", "text/html");
    const response = await fetch(
      `/api/market/mercanet/ui?baseUri=/api&wishlists=${wishlistIds}&customer=${customerId}&redirectUrl=${encodeURIComponent(
        redirectUrl
      )}`,
      {
        headers,
      }
    );
    const data = await response.blob();
    const dataUrl = URL.createObjectURL(data);
    this.paymentIframe.src = dataUrl;
  }

  componentDidMount() {
    this.parameterService.findCustomerPlatform().then((data: any) => {
      if (data !== undefined) {
        if (data.length > 1) {
          this.setState({
            multiPlatform: true,
          });
        } else {
          let platformId: number =
            this.props.values.basketContent[0].platform.id;

          this.parameterService
            .findValuePlatform(platformId, "PLATFORM_PAYMENT_ORDER")
            .then((value: string) => {
              if (value !== undefined) {
                this.setState({ paymentOrderPlatform: JSON.parse(value) });
              } else {
                this.setState({
                  paymentOrderPlatform: false,
                });
              }
            });
        }
      }
    });

    this.parameterService
      .findValueCustomer("CUSTOMER_PAYMENT_ORDER")
      .then((value: string) => {
        if (value !== undefined) {
          this.setState({
            paymentOrderCustomer: value,
          });
        } else {
          this.setState({
            paymentOrderCustomer: "disabled",
          });
        }
      });
  }

  async componentDidUpdate() {
    let listWishList: number[] = [];
    this.props.values.salesOrder.orderPlatforms.forEach(
      (orderPlatforms: IOrderPlatforms) => {
        if (orderPlatforms.wishlist !== undefined) {
          listWishList.push(orderPlatforms.wishlist.id);
        }
      }
    );

    this.loadIframe(Number(this.customerId), listWishList);
  }

  render() {
    return (
      <div>
        {!this.state.paymentOrderCustomer && (
          <div
            style={{ textAlign: "center", marginTop: 100, marginBottom: 100 }}
          >
            Le service de paiement est momentanément indisponible.
            <br />
            Nous mettons tout en oeuvre pour résoudre cet incident dans les
            meilleurs délais. <br />
            Merci de votre compréhension.
          </div>
        )}

        {this.state.paymentOrderCustomer && (
          <div>
            <Row>
              <Col span={24}>
                <div
                  style={{ float: "right", marginBottom: 10, marginTop: 10 }}
                >
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      this.props.onNextStep(
                        this.props.stepConf?.prevAction.prevStep
                      )
                    }
                    hidden={!this.props.stepConf?.prevAction.enable}
                    style={{ marginRight: 5 }}
                  >
                    <LeftOutlined />
                    {this.props.stepConf?.prevAction.text}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      this.props.onNextStep(
                        this.props.stepConf?.nextAction.nextStep
                      )
                    }
                    hidden={!this.props.stepConf?.nextAction.enable}
                    disabled={
                      this.props.values.wishlistTotal.totalQuantitySelected <=
                        0 ||
                      this.state.selectPaymentMethod === undefined ||
                      this.state.selectPaymentMethod ===
                        PaymentMethod.CREDIT_CARD
                    }
                  >
                    {this.props.stepConf?.nextAction.text}
                    <RightOutlined />
                  </Button>
                </div>
              </Col>
            </Row>

            <Radio.Group
              style={{ width: "100%" }}
              // value={this.state.selectPaymentMethod}
              // defaultValue={
              //   (this.state.paymentOrderCustomer === "disabled" &&
              //     PaymentMethod.BUSINESS_ACCOUNT) ||
              //   (!this.state.multiPlatform &&
              //     this.state.paymentOrderCustomer === "mandatory" &&
              //     this.state.paymentOrderPlatform &&
              //     PaymentMethod.CREDIT_CARD) ||
              //   (this.state.paymentOrderCustomer === "optional" && " ")
              // }
            >
              {(this.state.paymentOrderCustomer === "disabled" ||
                this.state.paymentOrderCustomer === "optional") && (
                <Card
                  type="inner"
                  bodyStyle={{ padding: 0 }}
                  title={
                    <Radio
                      onClick={() =>
                        this.onUpdateSalesOder(
                          PaymentState.ACCEPTED,
                          PaymentMethod.BUSINESS_ACCOUNT
                        )
                      }
                      value={PaymentMethod.BUSINESS_ACCOUNT}
                      // checked={this.state.paymentOrderCustomer && true}
                    >
                      <Text strong>
                        <FontAwesomeIcon icon={faHandshake} /> Mes conditions de
                        règlement habituelles
                      </Text>
                    </Radio>
                  }
                  style={{ marginBottom: 15, cursor: "pointer" }}
                  onClick={() =>
                    this.onUpdateSalesOder(
                      PaymentState.ACCEPTED,
                      PaymentMethod.BUSINESS_ACCOUNT
                    )
                  }
                >
                  {this.state.selectPaymentMethod ===
                    PaymentMethod.BUSINESS_ACCOUNT && (
                    <div style={{ padding: 16 }}>
                      <p>
                        Vous êtes un client en compte, vous avez choisi de payer
                        en utilisant vos conditions de règlement habituelles :
                      </p>
                      <ul>
                        <li>
                          Le montant total de votre commande s'élève à{" "}
                          <b>
                            {this.flybyUtils.numberFormat(
                              this.props.values.wishlistTotal
                                .totalPaymentAmountInclTaxesSelected,
                              2
                            )}{" "}
                            € TTC
                          </b>
                        </li>
                        <li>
                          Veuillez confirmer votre commande en cliquant sur «{" "}
                          {this.props.stepConf?.nextAction.text} »
                        </li>
                      </ul>
                    </div>
                  )}
                </Card>
              )}
              {!this.state.multiPlatform &&
                (this.state.paymentOrderCustomer === "mandatory" ||
                  this.state.paymentOrderCustomer === "optional") &&
                this.state.paymentOrderPlatform && (
                  <Card
                    type="inner"
                    bodyStyle={{ padding: 0 }}
                    title={
                      <Radio
                        // defaultChecked={true}
                        onClick={() =>
                          this.onUpdateSalesOder(
                            PaymentState.PENDING,
                            PaymentMethod.CREDIT_CARD
                          )
                        }
                        value={PaymentMethod.CREDIT_CARD}
                      >
                        <Text strong>
                          <FontAwesomeIcon icon={faCreditCard} /> Carte bancaire
                        </Text>
                      </Radio>
                    }
                    style={{ marginBottom: 15, cursor: "pointer" }}
                    onClick={() =>
                      this.onUpdateSalesOder(
                        PaymentState.PENDING,
                        PaymentMethod.CREDIT_CARD
                      )
                    }
                  >
                    {this.state.selectPaymentMethod ===
                      PaymentMethod.CREDIT_CARD && (
                      <div style={{ padding: 16 }}>
                        <p>
                          La transaction s'effectuera sur un serveur bancaire
                          sécurisé où les informations nécessaires vous seront
                          demandées.
                          <br />
                          A n'importe quel moment vous pourrez revenir au choix
                          des moyens de paiement sur notre boutique en cliquant
                          sur le bouton d'annulation depuis le serveur bancaire.
                          <br />
                          Cliquez sur le logo correspondant à votre carte pour
                          être redirigé vers le serveur bancaire adéquat.
                          <br />
                        </p>
                        <iframe
                          ref={(ref: HTMLIFrameElement) =>
                            (this.paymentIframe = ref)
                          }
                          width="100%"
                          height="140px"
                          title="payment"
                          style={{ border: "none" }}
                        />
                      </div>
                    )}
                  </Card>
                )}

              {/* <Card type="inner" bodyStyle={{ padding: 0 }}
                title={<Radio onClick={() => this.onUpdateSalesOder(PaymentState.PENDING, PaymentMethod.TRANSFER)}
                  value={PaymentMethod.TRANSFER}><Text strong><FontAwesomeIcon icon={faExchangeAlt} /> Virement bancaire</Text></Radio>} style={{ marginBottom: 15, cursor: 'pointer' }}
                onClick={() => this.onUpdateSalesOder(PaymentState.PENDING, PaymentMethod.TRANSFER)}>
                {this.state.selectPaymentMethod === PaymentMethod.TRANSFER &&
                  <div style={{ padding: 16 }}>
                    <p>       Vous avez choisi de régler par virement bancaire. Voici un bref récapitulatif de votre commande :</p>
                    <ul>
                      <li>Le montant total de votre commande s'élève à <b>{this.flybyUtils.numberFormat(this.props.values.wishlistTotal.totalPaymentAmountInclTaxesSelected, 2)} € TTC</b></li>
                      <li>Nous acceptons la devise suivante pour votre paiement : Euro</li>
                      <li>Nos coordonnées bancaires seront affichées sur la page suivante</li>
                      <li>Veuillez confirmer votre commande en cliquant sur « {this.props.stepConf?.nextAction.text} »</li>
                    </ul>
                  </div>
                }
              </Card> */}
            </Radio.Group>

            <div style={{ float: "right", marginBottom: 10, marginTop: 10 }}>
              <Button
                type="primary"
                size="large"
                onClick={() =>
                  this.props.onNextStep(
                    this.props.stepConf?.prevAction.prevStep
                  )
                }
                hidden={!this.props.stepConf?.prevAction.enable}
                style={{ marginRight: 5 }}
              >
                <LeftOutlined />
                {this.props.stepConf?.prevAction.text}
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() =>
                  this.props.onNextStep(
                    this.props.stepConf?.nextAction.nextStep
                  )
                }
                hidden={!this.props.stepConf?.nextAction.enable}
                disabled={
                  this.props.values.wishlistTotal.totalQuantitySelected <= 0 ||
                  this.state.selectPaymentMethod === undefined ||
                  this.state.selectPaymentMethod === PaymentMethod.CREDIT_CARD
                }
              >
                {this.props.stepConf?.nextAction.text}
                <RightOutlined />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  private onUpdateSalesOder = (paymentState: string, paymentMethod: string) => {
    let salesOrder: ISalesOrder = {
      ...this.props.values.salesOrder,
      paymentState: paymentState,
      paymentMethod: paymentMethod,
    };
    this.props.updateSalesOrder(salesOrder);

    this.setState({
      selectPaymentMethod: paymentMethod,
    });
  };

  // private loadDataPlatform = () => {
  //   this.parameterService.findCustomerPlatform().then((data: any) => {
  //     if (data !== undefined) {
  //       if (data.length > 1) {
  //         this.setState({
  //           multiPlatform: true,
  //         });
  //       }
  //       console.log(data.length);
  //     }
  //   });
  // };

  // private loadDataPlatform = () => {
  //   this.parameterService.search("", undefined, undefined)
  //     .then((data: any) => {
  //       if (data !== undefined) {

  //         let platformList: any = [];

  //         data.content.forEach((platform: IPlatform) => {
  //           platformList.push(<Option value={platform.id.toString()} key={platform.id.toString()}>{platform.code + " - " + platform.name}</Option>);
  //         });

  //         this.setState({
  //           platformList: platformList,
  //         });
  //       }
  //     });
  // }
}

export default Step5;
