import { Image, Skeleton } from "antd";
import React from "react";
import CatalogService from "../../catalog/CatalogService";
import IArticleCriterion from "../../catalog/model/IArticleCriterion";
import IArticle from "../IArticle";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";

class ArticleImage extends React.Component<
  { item: IArticle },
  {
    dataLoading: boolean;
    images: ReactImageGalleryItem[] | undefined;
  }
> {
  private catalogService: CatalogService = new CatalogService();

  constructor(props: { item: IArticle }) {
    super(props);

    this.state = {
      dataLoading: true,
      images: undefined,
    };
  }

  componentDidMount() {
    this.loadForm(this.props.item.id);
  }

  componentWillReceiveProps(newProps: { item: IArticle }) {
    if (newProps.item.id !== this.props.item.id) {
      this.loadForm(newProps.item.id);
    }
  }

  render() {
    return (
      <Skeleton loading={this.state.dataLoading}>
        {this.state.images !== undefined && this.state.images.length > 0 && (
          <ImageGallery
            items={this.state.images}
            showPlayButton={false}
            showNav={false}
          />
        )}

        {(this.state.images === undefined ||
          this.state.images.length === 0) && (
          <Image
            src={"/api/market/articles/" + this.props.item.id + "/image"}
            key={this.props.item.reference}
            alt={this.props.item.reference}
            style={{ width: "100%", maxWidth: 130, maxHeight: 130 }}
          />
        )}
      </Skeleton>
    );
  }

  private loadForm = (articleId: number) => {
    this.catalogService
      .findArticleCriterionByArticle(articleId, undefined, 0, "rank,asc")
      .then((data: any) => {
        if (data !== undefined && data.content) {
          let values: ReactImageGalleryItem[] = [];
          data.content.forEach((criteria: IArticleCriterion) => {
            if (criteria.criterion.code === "ARTICLE_IMG") {
              values.push({
                original: criteria.value,
                thumbnail: criteria.value,
              });
            }
          });
          this.setState({
            dataLoading: false,
            images: values,
          });
        } else {
          this.setState({
            dataLoading: false,
            images: undefined,
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataLoading: false,
          images: undefined,
        });
      });
  };
}

export default ArticleImage;
