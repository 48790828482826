import { Link } from "react-router-dom";
import {
  DefaultParameter,
  MarketParameterContext,
} from "../../Utils/MarketParameterContext";
import { Badge, Menu, MenuProps } from "antd";
import {
  ContainerOutlined,
  FileDoneOutlined,
  HddOutlined,
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  LogoutOutlined,
  MailOutlined,
  ReadOutlined,
  RollbackOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useCallback } from "react";
import { NavigationContext } from "../../Utils/NavigationProvider";
import { BasketContext } from "../../Utils/BasketProvider";
import HeaderPhone from "../../Utils/HeaderPhone";

interface CustomizedMenuProps {
  roleState: string;
  showWishlistState: boolean;
  setShowWishlistState: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAuthenticatedState: React.Dispatch<React.SetStateAction<number>>;
}

const CustomizedMenu = ({
  roleState,
  showWishlistState,
  setShowWishlistState,
  setIsAuthenticatedState,
}: CustomizedMenuProps): JSX.Element => {
  const onLogout = useCallback(() => {
    sessionStorage.clear();
    setIsAuthenticatedState(1);
  }, [setIsAuthenticatedState]);

  const onShowBasket = useCallback(
    (value?: boolean) => {
      if (roleState !== "ROLE_GUEST") {
        if (value !== undefined && value !== null) {
          setShowWishlistState(!value);
        } else {
          setShowWishlistState(!showWishlistState);
        }
      }
    },
    [roleState, setShowWishlistState, showWishlistState]
  );

  const itemsGenerated = useCallback(
    (appParms: DefaultParameter): MenuProps["items"] => {
      const items: MenuProps["items"] = [
        {
          label: <Link to="/home">Accueil</Link>,
          key: "/home",
          icon: <HomeOutlined />,
        },
        {
          label: <Link to="/mail">Nous contacter</Link>,
          key: "/mail",
          icon: <MailOutlined />,
        },
        {
          label: "Mon compte",
          key: "/account",
          icon: <IdcardOutlined />,
          disabled: roleState === "ROLE_GUEST",
          theme: "light",
          children: [
            {
              label: <Link to="/documents-history">Mes documents</Link>,
              key: "/documents-history",
              icon: <ReadOutlined />,
            },
            appParms.CUSTOM_DOCUMENTS_HISTORY !== null &&
            appParms.CUSTOM_DOCUMENTS_HISTORY !== ""
              ? {
                  label: appParms.CUSTOM_DOCUMENTS_HISTORY,
                  key: "/custom-documents-history",
                  icon: <ContainerOutlined />,
                }
              : null,
          ],
        },
        {
          label: <Link to="/returns">Demandes de retour</Link>,
          key: "/returns",
          icon: <RollbackOutlined />,
          disabled: roleState === "ROLE_GUEST",
        },
        appParms.DOCUMENT
          ? {
              label: <Link to="/library">Documentation</Link>,
              key: "/library",
              icon: <HddOutlined />,
              disabled: roleState === "ROLE_GUEST",
            }
          : null,
        !appParms.DISABLE_MARKET
          ? {
              label: <Link to="/bookmark">Mes favoris</Link>,
              key: "/bookmark",
              icon: <StarOutlined />,
              disabled: roleState === "ROLE_GUEST",
            }
          : null,
        !appParms.DISABLE_MARKET
          ? {
              label: "Mes commandes",
              key: "/sales-order",
              icon: <FileDoneOutlined />,
              theme: "light",
              children: [
                {
                  label: (
                    <Link to="/sales-order-history">
                      Historique des commandes
                    </Link>
                  ),
                  key: "/sales-order-history",
                  icon: <HistoryOutlined />,
                },
                {
                  label: (
                    <Link to="/import-sales-order">Importer une commande</Link>
                  ),
                  key: "/import-sales-order",
                  icon: <UploadOutlined />,
                  disabled: roleState === "ROLE_GUEST",
                },
              ],
            }
          : null,

        !appParms.DISABLE_MARKET
          ? {
              label: (
                <BasketContext.Consumer>
                  {(value) => (
                    <Link to="/shopping-cart">
                      <Badge
                        count={value.wishlistTotal.totalQuantity}
                        overflowCount={999}
                        offset={[0, -5]}
                        showZero={false}
                      >
                        <div style={{ visibility: "hidden" }}>----</div>
                      </Badge>
                    </Link>
                  )}
                </BasketContext.Consumer>
              ),
              key: "/shopping-cart",
              icon: (
                <ShoppingCartOutlined
                  style={{
                    fontSize: 30,
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    textAlign: "center",
                    padding: 0,
                  }}
                />
              ),
              disabled: roleState === "ROLE_GUEST",
            }
          : null,
        {
          label: (
            <UserOutlined
              style={{
                fontSize: 35,
                verticalAlign: "middle",
              }}
            />
          ),
          key: "/sales-user-account",
          theme: "light",
          children: [
            sessionStorage.getItem("displayNameAndCompanyName") === "display" &&
            sessionStorage.getItem("userFirstName") !== "-NA-" &&
            sessionStorage.getItem("userLastName") !== "-NA-"
              ? {
                  type: "group",
                  label: (
                    <h3>
                      {sessionStorage.getItem("userFirstName")}{" "}
                      {sessionStorage.getItem("userLastName")?.toUpperCase()}
                    </h3>
                  ),
                  key: "/name",
                }
              : null,
            sessionStorage.getItem("displayNameAndCompanyName") === "display"
              ? {
                  type: "group",
                  label: (
                    <h3>
                      id utilisateur :{" "}
                      {sessionStorage.getItem("userUsername")?.toUpperCase()}
                    </h3>
                  ),
                  key: "/username",
                }
              : null,
            sessionStorage.getItem("displayNameAndCompanyName") === "display"
              ? {
                  type: "group",
                  label: (
                    <h3>
                      {sessionStorage.getItem("customerName")?.toUpperCase()}
                    </h3>
                  ),
                  key: "/customer-name",
                }
              : null,
            appParms.PHONE
              ? {
                  type: "group",
                  label: <HeaderPhone />,
                  key: "/phone-number",
                }
              : null,
            {
              label: <Link to="/my-acccount">Modifier mon mot de passe</Link>,
              key: "/my-acccount",
            },
            !appParms.DISABLE_MARKET
              ? {
                  label: showWishlistState
                    ? "Afficher le panier"
                    : "Masquer le panier",
                  key: "/basket",
                  icon: <ShoppingCartOutlined />,
                  onClick: () => onShowBasket(),
                }
              : null,
            {
              label: (
                <Link to="/" style={{ color: "#ffffff" }}>
                  Déconnexion
                </Link>
              ),
              key: "/",
              icon: <LogoutOutlined />,
              style: {
                borderTop: "1px solid #ececec",
                backgroundColor: "#001529",
                color: "#ffffff",
              },
              onClick: () => onLogout(),
            },
          ],
        },
      ];
      return items;
    },
    [onLogout, onShowBasket, roleState, showWishlistState]
  );

  return (
    <MarketParameterContext.Consumer>
      {(appParms) => (
        <NavigationContext.Consumer>
          {(appMenu) => (
            <Menu
              onClick={(selectedMenu) =>
                appMenu.updateMenuKey(selectedMenu.key)
              }
              selectedKeys={[appMenu.selectedMenuKey]}
              mode="horizontal"
              // theme="light"
              theme={appParms.MENU_THEME === "dark" ? "dark" : "light"}
              style={{
                justifyContent: "space-between",
                height: "100%",
                width: "auto",
                maxHeight: "100%",
                maxWidth: "auto",
              }}
              items={itemsGenerated(appParms)}
            />
          )}
        </NavigationContext.Consumer>
      )}
    </MarketParameterContext.Consumer>
  );
};

export default CustomizedMenu;
