import React from "react";
import { Table, Typography, Skeleton, List, Tag, Row, Col, Image } from "antd";
import { ColumnProps } from "antd/lib/table";

import { withBasket, BasketContext } from "../../../Utils/BasketProvider";
import ITecdocSearch from "../model/ITecdocSearch";
import TecdocService from "../TecdocService";
import FlybyUtils from "../../../Utils/FlybyUtils";
import IData from "../../../Utils/IData";
import IArticle from "../model/IArticle";
import TecdocArticleSearch from "./TecdocArticleSearch";
import ArticleFlybyDisplay from "./article/ArticleFlybyDisplay";
import IArticlePlatforms from "../../home/IArticlePlatforms";
import ArticleService from "../../home/ArticleService";
import ArticleUtils from "../../home/ArticleUtils";
import { WarningOutlined } from "@ant-design/icons";
import ArticleWishlistForm from "../../home/ArticleWishlistForm";
import IArticleHome from "../../home/IArticle";
import IMasterdataSupplier from "../../home/IMasterdataSupplier";
import IArticleCriteria from "../model/IArticleCriteria";
import ILinkageAttribute from "../model/ILinkageAttribute";

const { Text } = Typography;

class TecdocArticle extends React.Component<
  {
    tecdocSearch: ITecdocSearch;
    setTecdocSearch: any;
    articleView: string;
  },
  {
    data: IData;
    dataLoading: boolean;
    nodeId: number | undefined;
    filters: {
      suppliers: undefined | number[];
      criterion: undefined | number[];
    };
  }
> {
  private tecdocService: TecdocService = new TecdocService();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private articleService: ArticleService = new ArticleService();
  private articleUtils: ArticleUtils = new ArticleUtils();
  private pageSizeList: number = 5;
  private pageSizeTable: number = 10;
  private customerId = sessionStorage.getItem("customerId");
  private showPlatformList: boolean =
    sessionStorage.getItem("showPlatformList") === "false" ? false : true;

  private columnsTable: ColumnProps<IArticle>[] = [
    {
      title: "",
      dataIndex: "image",
      render: (text: string, item: IArticle) => (
        <Image
          src={"/tecdoc-api/articles/" + item.artNr + "/image?country=fr"}
          key={item.artNr}
          alt={item.artNr}
          style={{ maxHeight: 50 }}
        />
      ),
    },
    {
      title: "Référence",
      dataIndex: "reference",
      render: (text: string, item: IArticle) => <div>{item.artNr}</div>,
    },
    {
      title: "Description",
      dataIndex: "artNr",
      render: (text: string, item: IArticle) => (
        <div>{item.genericArticle.bez}</div>
      ),
    },
    {
      title: "Carractéristiques",
      dataIndex: "criteria",
      render: (text: string, item: IArticle) => (
        <div>
          {this.sortBySortNr(item.articleCriteria).map(
            (criteria: IArticleCriteria) => (
              <div key={criteria.kritNr + "" + criteria.sortNr}>
                {criteria.typ !== "K" && criteria.typ !== "V" && (
                  <span>
                    <Text strong>{criteria.bezCriteriaAbk} : </Text>{" "}
                    {criteria.kritWert} {criteria.bezCriteriaEinheit}
                    <br />
                  </span>
                )}
              </div>
            )
          )}
          {this.sortBySortNr(item.linkageAttributes).map(
            (attribute: ILinkageAttribute) => (
              <div key={attribute.kritNr + "" + attribute.sortNr}>
                {attribute.typ !== "K" && attribute.typ !== "V" && (
                  <span>
                    <Text strong>{attribute.bezCriteriaAbk} : </Text>{" "}
                    {attribute.kritWert} {attribute.bezCriteriaEinheit}
                    <br />
                  </span>
                )}
                {attribute.typ === "K" &&
                  (attribute.bezKeyEntry != null ||
                    attribute.bezCriteriaAbk != null) && (
                    <span>
                      <Text strong>{attribute.bezCriteriaAbk} : </Text>{" "}
                      {attribute.bezKeyEntry}
                      <br />
                    </span>
                  )}
              </div>
            )
          )}
        </div>
      ),
    },
    {
      title: "Plateforme",
      dataIndex: "platform",
      render: (text: string, item: IArticle) => (
        <div>
          <Skeleton loading={item.loading} active>
            {item.articleSearchResult !== undefined && (
              <ArticleWishlistForm
                item={item.articleSearchResult.article}
                onSelectPlatform={(value: any) =>
                  this.onChangeSelect(value, item.articleSearchResult?.article)
                }
                onChangeQuantity={this.onChangeQuantity}
              />
            )}
            {item.articleSearchResult === undefined && (
              <Text type="warning">Article non disponible à la vente.</Text>
            )}
          </Skeleton>
        </div>
      ),
      width: 350,
    },
  ];

  constructor(props: {
    tecdocSearch: ITecdocSearch;
    setTecdocSearch: any;
    articleView: string;
  }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
      nodeId: undefined,
      filters: {
        suppliers: undefined,
        criterion: undefined,
      },
    };
  }

  componentDidMount() {
    if (
      this.props.tecdocSearch.vehicle !== undefined &&
      this.props.tecdocSearch.searchStructure !== undefined &&
      this.props.tecdocSearch.vehicle.ktypNr !== undefined
    ) {
      this.search(
        this.props.tecdocSearch.searchStructure.nodeId,
        this.props.tecdocSearch.vehicle.ktypNr,
        this.pageSize(this.props.articleView),
        0
      );
    }
  }

  componentWillReceiveProps(newProps: {
    tecdocSearch: ITecdocSearch;
    setTecdocSearch: any;
    articleView: string;
  }) {
    if (
      newProps.tecdocSearch.vehicle !== undefined &&
      newProps.tecdocSearch.searchStructure !== undefined &&
      newProps.tecdocSearch.vehicle.ktypNr !== undefined &&
      (newProps.tecdocSearch.searchStructure.nodeId !== this.state.nodeId ||
        newProps.articleView !== this.props.articleView)
    ) {
      this.search(
        newProps.tecdocSearch.searchStructure.nodeId,
        newProps.tecdocSearch.vehicle.ktypNr,
        this.pageSize(newProps.articleView),
        0
      );
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => (
          <div>
            <TecdocArticleSearch onFilter={this.onFilter} />

            {contextValues.articleView === "table" && (
              <Table
                rowKey="artNr"
                loading={this.state.dataLoading}
                columns={this.columnsTable}
                showHeader={true}
                bordered={true}
                dataSource={this.state.data.content}
                onChange={this.onChange}
                pagination={{
                  current: this.state.data.number + 1,
                  pageSize: this.state.data.size,
                  total: this.state.data.totalElements,
                }}
                style={{ marginTop: 10 }}
                expandedRowRender={(
                  item: IArticle,
                  index: number,
                  indent: number,
                  expanded: boolean
                ) => (
                  <Text type="secondary">
                    {expanded && (
                      <Row>
                        <Col span={8}>
                          {item.articleSearchResult?.article.availability !==
                            undefined && (
                            <div>
                              <Tag
                                color={this.flybyUtils.getAvailabilityColor(
                                  item.articleSearchResult.article.availability
                                    .state,
                                  item.articleSearchResult.article.availability
                                    .locallyAvailable,
                                  item.articleSearchResult.article.availability
                                    .deliveredAt,
                                  item.articleSearchResult.article.availability
                                    .quantityBackorder,
                                  item.articleSearchResult.article.availability
                                    .longerAvailabilityAlert
                                )}
                              >
                                {this.flybyUtils.getAvailabilityText(
                                  item.articleSearchResult.article.availability
                                    .state,
                                  item.articleSearchResult.article.availability
                                    .deliveredAt,
                                  item.articleSearchResult.article.availability
                                    .quantityBackorder,
                                  item.articleSearchResult.article.availability
                                    .longerAvailabilityAlert,
                                  undefined
                                )}
                              </Tag>
                              {this.flybyUtils.isSupplierAvailability(
                                item.articleSearchResult.article.availability
                                  .quantityBackorder
                              )}
                              <br />
                            </div>
                          )}
                          <Text strong>Référence :</Text>
                          {item.articleSearchResult?.article.reference}
                          <br />
                          <Text strong>Fabricant :</Text>{" "}
                          {item.articleSearchResult?.article.manufacturer.name}
                          <br />
                          {item.articleSearchResult !== undefined && (
                            <Image
                              src={
                                "/api/market/manufacturers/" +
                                item.articleSearchResult?.article.manufacturer
                                  .id +
                                "/logo?customer=" +
                                this.customerId
                              }
                              key={item.articleSearchResult?.article.reference}
                              alt={item.articleSearchResult?.article.reference}
                              style={{ marginTop: 5, width: 80 }}
                            />
                          )}
                        </Col>
                        <Col span={8}>
                          {item.articleSearchResult !== undefined &&
                            item.articleSearchResult.article.availability !==
                              undefined && (
                              <div>
                                <Text strong>EAN(s) :</Text>{" "}
                                {this.flybyUtils.getEans(
                                  item.articleSearchResult.article.eans
                                )}
                                <br />
                                <Text strong>Date de livraison : </Text>
                                {this.flybyUtils.formatDeliveredAt(
                                  item.articleSearchResult.article.availability
                                    .deliveredAt
                                )}
                                <br />
                                {this.showPlatformList &&
                                  item.articleSearchResult.article.availability
                                    .locallyAvailable && (
                                    <span>
                                      <Text strong>Provenance : </Text>
                                      {
                                        item.articleSearchResult.article
                                          .availability.description
                                      }
                                    </span>
                                  )}
                                {this.showPlatformList &&
                                  !item.articleSearchResult.article.availability
                                    .locallyAvailable && (
                                    <span>
                                      <Text strong>Provenance : </Text>
                                      <Text strong type="danger">
                                        <WarningOutlined /> Autre plateforme
                                      </Text>
                                    </span>
                                  )}
                              </div>
                            )}
                        </Col>
                        <Col span={8}>
                          <span>
                            <Text strong>Prix unitaire € HT : </Text>
                            {item.articleSearchResult?.article.availability !==
                            undefined
                              ? this.flybyUtils.numberFormat(
                                  item.articleSearchResult?.article.availability
                                    .grossUnitPrice,
                                  2
                                )
                              : ""}
                          </span>
                          <br />
                          <span>
                            <Text strong>Remise % : </Text>
                            {item.articleSearchResult?.article.availability !==
                            undefined
                              ? this.flybyUtils.numberFormat(
                                  item.articleSearchResult?.article.availability
                                    .discount,
                                  2
                                )
                              : ""}
                          </span>
                          <br />
                          <span>
                            <Text strong>Vendu par : </Text>
                            {item.articleSearchResult?.article.availability !==
                            undefined
                              ? item.articleSearchResult?.article.availability
                                  .salePackaging
                              : ""}
                          </span>
                          <br />
                          <span>
                            <Text strong>Consigne : </Text>
                            {item.articleSearchResult?.article.availability !==
                            undefined
                              ? this.flybyUtils.numberFormat(
                                  item.articleSearchResult?.article.availability
                                    .consignmentPrice,
                                  2
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    )}
                  </Text>
                )}
              />
            )}

            {contextValues.articleView === "list" && (
              <List
                loading={this.state.dataLoading}
                style={{ marginTop: 10 }}
                grid={{ gutter: 8, column: 1 }}
                dataSource={this.state.data.content}
                pagination={{
                  onChange: this.onChangeList,
                  current: this.state.data.number + 1,
                  pageSize: this.state.data.size,
                  total: this.state.data.totalElements,
                }}
                renderItem={(item: IArticle) => (
                  <List.Item>
                    <ArticleFlybyDisplay
                      tecdocArticle={item}
                      onChangeSelect={this.onChangeSelect}
                      onChangeQuantity={this.onChangeQuantity}
                    />
                  </List.Item>
                )}
              />
            )}
          </div>
        )}
      </BasketContext.Consumer>
    );
  }

  private search = (
    nodeId: number,
    ktypNr: number,
    pageSize: number,
    pageNumber: number
  ) => {
    const filters = this.state.filters;
    if (filters.suppliers !== undefined || filters.criterion !== undefined) {
      this.loadTecdocArticlesByDlNrAndKey(
        nodeId,
        ktypNr,
        filters.suppliers !== undefined ? filters.suppliers.join(",") : "",
        filters.criterion !== undefined ? filters.criterion.join(",") : "",
        pageSize,
        pageNumber
      );
    } else {
      this.loadTecdocArticles(nodeId, ktypNr, pageSize, pageNumber);
    }
  };

  private onChangeList = (pageNumber: number, pageSize?: number) => {
    if (
      this.props.tecdocSearch.vehicle !== undefined &&
      this.props.tecdocSearch.searchStructure !== undefined &&
      this.props.tecdocSearch.vehicle.ktypNr !== undefined
    ) {
      this.search(
        this.props.tecdocSearch.searchStructure.nodeId,
        this.props.tecdocSearch.vehicle.ktypNr,
        this.pageSize(this.props.articleView),
        pageNumber - 1
      );
    }
  };

  private onChangeSelect = (
    platformId: any,
    item: IArticleHome | undefined
  ) => {
    if (item !== undefined) {
      this.articleUtils.onChangeSelect(this, platformId, item);
    }
  };

  private onChangeQuantity = (
    quantity: number,
    platformId: number,
    item: IArticleHome | undefined
  ) => {
    if (item !== undefined) {
      this.articleUtils.onChangeQuantity(this, quantity, platformId, item);
    }
  };

  private onChange = (
    pagination: any,
    filters: Record<never, string[]>,
    sorter: any,
    extra: any
  ) => {
    if (
      this.props.tecdocSearch.vehicle !== undefined &&
      this.props.tecdocSearch.searchStructure !== undefined &&
      this.props.tecdocSearch.vehicle.ktypNr !== undefined
    ) {
      pagination = this.flybyUtils.getCurrentPage(pagination);
      this.search(
        this.props.tecdocSearch.searchStructure.nodeId,
        this.props.tecdocSearch.vehicle.ktypNr,
        this.pageSize(this.props.articleView),
        pagination.current
      );
    }
  };

  private onFilter = (values: any) => {
    if (
      this.props.tecdocSearch.vehicle !== undefined &&
      this.props.tecdocSearch.searchStructure !== undefined &&
      this.props.tecdocSearch.vehicle.ktypNr !== undefined
    ) {
      this.setState({
        filters: values,
      });

      this.loadTecdocArticlesByDlNrAndKey(
        this.props.tecdocSearch.searchStructure.nodeId,
        this.props.tecdocSearch.vehicle.ktypNr,
        values.suppliers !== undefined ? values.suppliers.join(",") : "",
        values.criterion !== undefined ? values.criterion.join(",") : "",
        this.pageSize(this.props.articleView),
        0
      );
    }
  };

  private loadTecdocArticlesByDlNrAndKey = (
    nodeId: number,
    vknzielNr: number,
    dlNr: string,
    key: string,
    pageSize: number | undefined,
    current: number | undefined
  ) => {
    this.setState({
      dataLoading: true,
    });

    this.tecdocService
      .findArticlesByDlNrAndKey(nodeId, vknzielNr, dlNr, key, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result,
            dataLoading: false,
          });

          this.state.data.content.forEach((item: IArticle) => {
            this.loadFlybyArticle(item);
          });
        } else {
          this.setState({
            dataLoading: false,
          });
        }
      });
  };

  private loadTecdocArticles = (
    nodeId: number,
    vknzielNr: number,
    pageSize: number | undefined,
    current: number | undefined
  ) => {
    this.setState({
      dataLoading: true,
    });

    this.tecdocService
      .findArticles(nodeId, vknzielNr, pageSize, current)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result,
            dataLoading: false,
            nodeId: nodeId,
          });

          this.state.data.content.forEach((item: IArticle) => {
            this.loadFlybyArticle(item);
          });
        } else {
          this.setState({
            dataLoading: false,
            nodeId: nodeId,
          });
        }
      });
  };

  private loadFlybyArticle = (item: IArticle) => {
    item.loading = true;

    this.articleService
      .findMasterDataSupplier(item.supplier.dlnr)
      .then((masterdataSupplier: IMasterdataSupplier) => {
        if (masterdataSupplier !== undefined) {
          this.articleService
            .searchArticleByReference(
              item.cleanReference,
              masterdataSupplier.teccomCode
            )
            .then((result: any) => {
              if (result !== undefined && result.content.length > 0) {
                let articleSearch = result.content[0];
                let articlePlatform: IArticlePlatforms =
                  articleSearch.article.articlePlatforms[0];

                if (articlePlatform !== undefined) {
                  articleSearch.article.loading = true;
                  articleSearch.article.quantity =
                    articlePlatform.salePackaging;
                  articleSearch.article.equivalence = {
                    loading: true,
                    list: [],
                  };

                  if (articleSearch.known) {
                    this.articleUtils.checkArticleAvailability(
                      this,
                      undefined,
                      articleSearch.article,
                      false
                    );
                    this.articleUtils.findEquivalences(
                      this,
                      articleSearch.article,
                      false
                    );
                  }

                  item.articleSearchResult = articleSearch;
                }
              }

              item.loading = false;
              this.forceUpdate();
            });
        }
      });
  };

  private pageSize = (articleView: string) => {
    if (articleView === "table") {
      return this.pageSizeTable;
    }
    if (articleView === "list") {
      return this.pageSizeList;
    }

    return 10;
  };

  private sortBySortNr = (
    data: IArticleCriteria[] | ILinkageAttribute[]
  ): IArticleCriteria[] => {
    return data.sort((a: any, b: any) => {
      return a.sortNr - b.sortNr;
    });
  };
}

export default withBasket(TecdocArticle);
