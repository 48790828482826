class ParameterService {
  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async findCustomerPlatform(): Promise<[]> {
    return fetch("/api/customers/" + this.customerId + "/customerPlatforms", {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        if (
          data !== undefined &&
          data.content !== undefined &&
          data.content.length > 0
        ) {
          return data.content;
        }
        return undefined;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findValuePlatform(
    platformId: number,
    name: string
  ): Promise<string> {
    return fetch(
      "/api/market/parameters?platform=" +
        platformId +
        "&customer=" +
        this.customerId +
        "&name=" +
        name,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        if (
          data !== undefined &&
          data.content !== undefined &&
          data.content.length > 0
        ) {
          return data.content[0].value;
        }
        return undefined;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findValueCustomer(name: string): Promise<string> {
    return fetch(
      "/api/market/parameters?customer=" + this.customerId + "&name=" + name,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        if (
          data !== undefined &&
          data.content !== undefined &&
          data.content.length > 0
        ) {
          return data.content[0].value;
        }
        return undefined;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findValue(name: string): Promise<string> {
    return fetch(
      "/api/market/parameters?customer=" + this.customerId + "&name=" + name,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        if (
          data !== undefined &&
          data.content !== undefined &&
          data.content.length > 0
        ) {
          return data.content[0].value;
        }
        return undefined;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findValueByCustomerId(
    name: string,
    accessToken: string,
    customerId: number
  ): Promise<string> {
    return fetch(
      "/api/market/parameters?customer=" + customerId + "&name=" + name,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        return data.content[0].value;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findParametersByCustomerId(
    accessToken: string,
    customerId: number
  ): Promise<string> {
    return fetch(
      "/api/market/parameters?customer=" + customerId + "&size=1000&page=0",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        return data.content;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findInitParameters(): Promise<any> {
    return fetch("/api/market/parameters?size=1000&page=0", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        return data.content;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findInitParametersByPlatform(
    accessToken: string,
    platformId: number,
    parameterName: string
  ): Promise<string> {
    return fetch(
      `/api/market/parameters?name=${parameterName}&platform=${platformId}&customer=${this.customerId}&size=1000&page=0`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        return data.content[0].value;
      })
      .catch((err: any) => {
        console.log(err);
      });

    // .then((response: any) => {
    //   return response.json();
    // })
    // .then((data: any) => {
    //   return data.content;
    // })
    // .catch((err: any) => {
    //   console.log(err);
    // });
  }

  public async getPublicHtmlPage(page: string): Promise<string> {
    if (page !== "INC") {
      return fetch("/api/public/" + page)
        .then((response: any) => {
          if (response.status === 200) {
            return response.text();
          } else {
            return "";
          }
        })
        .catch((err: any) => {
          return "";
        });
    }
    return "";
  }
}

export default ParameterService;
